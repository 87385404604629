import React from 'react';
import cn from 'classnames';
import { HashLink as OriginHashLink } from 'react-router-hash-link';
import type { HashLinkProps } from 'react-router-hash-link';

import styles from './Link.pcss';

interface IHashLinkProps extends HashLinkProps {
  className?: string;
  disabled?: boolean;
}

const HashLink: React.FC<IHashLinkProps> = (props) => {
  const { className, disabled, children, ...restProps } = props;

  return (
    <OriginHashLink
      className={cn(styles.link, className, { [styles.disabled]: disabled })}
      {...restProps}
    >
      {children}
    </OriginHashLink>
  );
};

HashLink.defaultProps = {
  className: undefined,
  disabled: false,
};

export default HashLink;
