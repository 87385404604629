import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

import { useDispatch } from '../../store';
import { useHttpClient } from '../withHttpClient';
import SearchContext, { ISearchContext } from './Context';
import rememberLocationAction from '../../components/pages/Landing/actions';
import { COOKIE_QUERY, COOKIE_LOCATION } from './constants';
import { getCookieLocation } from './actions';

const WithSearch: React.FC = (props) => {
  const dispatch = useDispatch();
  const httpClient = useHttpClient();
  const [total, setTotal] = useState(0);
  const [isFetching, setFetching] = useState(false);
  const [cachedQuery, setCachedQuery] = useState('');
  const [cachedLocation, setCachedLocation] = useState(undefined);

  const handleQueryChange = (query = '') => {
    setCachedQuery(query);
    Cookies.set(COOKIE_QUERY, query);
  };

  const handleLocationChange = (location: object) => {
    setCachedLocation(location);
    Cookies.set(COOKIE_LOCATION, JSON.stringify(location));
    dispatch(rememberLocationAction(location));
  };

  useEffect(() => {
    const cookieQuery: string = Cookies.get(COOKIE_QUERY);
    const cookieLocation: object = getCookieLocation();

    if (cookieQuery) {
      handleQueryChange(cookieQuery);
    }

    if (cookieLocation) {
      handleLocationChange(cookieLocation);
    } else {
      httpClient
        .get('suggest/geo_attrs_by_ip')
        .then((response) => {
          if (response.success) {
            handleLocationChange(response.location);
          }
        })
        .catch(() => {});
    }
  }, []);

  const state: ISearchContext = {
    query: cachedQuery,
    setQuery: handleQueryChange,
    location: cachedLocation?.title,
    setLocation: handleLocationChange,
    total,
    setTotal,
    isFetching,
    setFetching,
  };

  return (
    <SearchContext.Provider value={state}>
      {props.children}
    </SearchContext.Provider>
  );
};

export default WithSearch;
