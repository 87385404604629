import React, { useMemo, useRef, useEffect } from 'react';
import LayerContext from './LayerContext';
import { useLayerManager } from '../../../../context/LayerManager';
import Scroll from '../../../../utils/scroll';

import styles from './Layer.pcss';

const Layer: React.FC = ({ children }) => {
  const layerManager = useLayerManager();
  const id = useMemo(layerManager.generateId, []);
  const layerEl = useRef<HTMLDivElement>();

  useEffect(() => {
    layerManager.onAdd(layerEl);
    return () => {
      layerManager.onRemove(layerEl);
    };
  }, []);

  return (
    <div ref={layerEl} id={id} className={styles.layer}>
      <LayerContext.Provider
        value={{
          ref: layerEl,
          disable: () => Scroll.disableLayerScroll(layerEl.current),
          enable: () => Scroll.enableLayerScroll(layerEl.current),
        }}
      >
        {children}
      </LayerContext.Provider>
    </div>
  );
};

export default Layer;
