import type { Store } from 'redux';
import type { History } from 'history';
import ReactGA from 'react-ga';

const initGoogleAnalytic = (gaId: string, store: Store, history: History) => {
  ReactGA.initialize(gaId);

  history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
  });
};

export default initGoogleAnalytic;
