import { combineReducers } from 'redux';

import { ACTION_KEY as PROFILE_ACTION_KEY } from './PersonalDetails/constants';
import profileReducer from './PersonalDetails/reducer';
import { ACTION_KEY as YOUR_IDEAL_JOB_KEY } from './YourIdealJob/redux/constants';
import yourIdeaJobReducer from './YourIdealJob/redux/reducer';
import { ACTION_KEY as COMMUNICATION_PREFERENCES_ACTION_KEY } from './CommunicationPreferences/constants';
import communicationPreferencesReducer from './CommunicationPreferences/reducer';

export default combineReducers({
  [PROFILE_ACTION_KEY]: profileReducer,
  [YOUR_IDEAL_JOB_KEY]: yourIdeaJobReducer,
  [COMMUNICATION_PREFERENCES_ACTION_KEY]: communicationPreferencesReducer,
});
