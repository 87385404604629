import * as Sentry from '@sentry/react';
// import * as SentryIntegrations from '@sentry/integrations';
import { Integrations as SentryTracingIntegrations } from '@sentry/tracing';
import Fingerprint2 from 'fingerprintjs2';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import curryRight from 'lodash/curryRight';
import flowRight from 'lodash/flowRight';
import type { Store } from 'redux';
import type { History } from 'history';
import {
  enhance as enhanceBreadcrumb,
  addToExtra as addBreadcrumbToExtra,
  captureEvent as captureEventOnBreadcrumb,
} from './breadcrumb';
import { enhance as enhanceEvent } from './event';

const SORT_SYMBOL = ' ';
const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

const initSentry = (store: Store, history: History) => {
  if (IS_DEVELOPMENT) {
    return;
  }

  Sentry.init({
    beforeSend: (event, hint) =>
      flowRight(curryRight(enhanceEvent)(hint, store))(event),
    beforeBreadcrumb: (breadcrumb, hint) =>
      flowRight(
        curryRight(captureEventOnBreadcrumb)(hint),
        addBreadcrumbToExtra,
        curryRight(enhanceBreadcrumb)(hint)
      )(breadcrumb),
    attachStacktrace: true,
    dsn: 'https://a1bca35bca504feaa3842c43c303edf7@sentry.io/296511',
    environment: process.env.ENV || process.env.NODE_ENV,
    ignoreErrors: [
      "null is not an object (evaluating 'd.frameElement')",
      "Cannot read property 'frameElement' of null",
      'ResizeObserver loop limit exceeded',
    ],
    release: process.env.COMMIT_HASH,
    maxBreadcrumbs: 100,
    normalizeDepth: Infinity,
    maxValueLength: Infinity,
    integrations: [
      new SentryTracingIntegrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      // new SentryIntegrations.ExtraErrorData(),
      // new SentryIntegrations.CaptureConsole(),
      // new SentryIntegrations.Dedupe(),
      // new SentryIntegrations.Debug(),
    ],
  });

  Sentry.configureScope((scope) => {
    scope.setTag('app.session', uuidv4());
    scope.setTag('ahoy.visit', Cookies.get('ahoy_visit'));

    const fingerprint2Options = {
      excludes: {
        plugins: true,
        canvas: true,
        webgl: true,
        webdriver: true,
        webglVendorAndRenderer: true,
        fonts: true,
        fontsFlash: true,
        audio: true,
        enumerateDevices: true,
      },
    };

    Fingerprint2.getPromise(fingerprint2Options).then((components) => {
      const fingerprint2 = components.reduce(
        (acc, { key, value }) => ({ ...acc, [key]: value }),
        {}
      );
      scope.setExtra(`${SORT_SYMBOL}fingerprint2`, fingerprint2);
    });
  });
};

export default initSentry;
