import React from 'react';

import { Link } from '../../../partials/Router';
import { useUrlMethods } from '../../../../context/withUrlMethods';

import styles from './PersonalDataWarning.pcss';

interface IPersonalDataWarningProps {
  ats?: boolean;
}

const PersonalDataWarning: React.FC<IPersonalDataWarningProps> = ({ ats }) => {
  const { buildClientPath } = useUrlMethods();
  return (
    <div className={styles.container}>
      {ats ? `You're` : `We're`} always in control of your personal data.
      &#x2008;
      <Link to={buildClientPath('/policy')} target="_blank">
        Learn more here.
      </Link>
    </div>
  );
};

PersonalDataWarning.defaultProps = {
  ats: false,
};

export default PersonalDataWarning;
