import { matchPath } from 'react-router-dom';
import type { LocationDescriptor } from 'history';
import type {
  ILazyPageRouteProps,
  ILazyPopupRouteProps,
} from '../../components/partials/Router';

const toToPath = (to: LocationDescriptor): string => {
  if (typeof to === 'string') {
    return to;
  }
  // RFC-3986 relative-ref = relative-part [ "?" query ] [ "#" fragment ]
  return `${to.pathname}${to.search || ''}${to.hash || ''}`;
};

export const getUrl = (path: LocationDescriptor) =>
  new URL(toToPath(path), process.env.HOST);

export const getMatchedPages = (
  pageRegistry: Map<string, ILazyPageRouteProps>,
  location: LocationDescriptor
) =>
  Array.from(pageRegistry, ([path, params]) => ({
    path,
    params,
  }))
    .filter(({ params }) => {
      const url = getUrl(location);
      return matchPath(url.pathname, params);
    })
    .map((regestry) => regestry.params.component);

export const getMatchedPopups = (
  popupRegistry: Map<string, ILazyPopupRouteProps>,
  location: LocationDescriptor
) =>
  Array.from(popupRegistry, ([path, params]) => ({
    path,
    params,
  }))
    .filter(({ path }) => {
      const url = getUrl(location);
      return url.searchParams.has(path);
    })
    .map((regestry) => regestry.params.component);
