import throttle from 'lodash/throttle';

const memoryStorage = {};

const parseJsonOrDefault = (value, fallback = undefined) => {
  try {
    return JSON.parse(value);
  } catch (err) {
    return fallback;
  }
};

export const loadState = (key) => {
  try {
    if (localStorage) {
      const serializedState = window.localStorage.getItem(key);
      if (serializedState === null) {
        return undefined;
      }
      return parseJsonOrDefault(serializedState, serializedState);
    }
    const value = memoryStorage[key];

    return value || null;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    if (localStorage) {
      if (state === undefined || state === null) {
        window.localStorage.removeItem(key);
      } else {
        const serializedState = JSON.stringify(state);
        window.localStorage.setItem(key, serializedState);
      }
    } else {
      memoryStorage[key] = state;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(
      'Smooch local storage warn: localStorage not available; falling back on memory storage'
    );
    memoryStorage[key] = state;
  }
};

export const throttledSaveToLocalStorage = throttle(
  (key, state) => saveState(key, state),
  1000
);

export const removeFromLocalStorage = (key) => {
  try {
    if (localStorage) {
      localStorage.removeItem(key);
    }
    delete memoryStorage[key];
  } catch (err) {
    delete memoryStorage[key];
  }
};
