import { TOGGLE } from './constants';

interface IMobileMenuState {
  isOpened: boolean;
}

const initialState = {
  isOpened: false,
};

interface IMobileMenuReducer {
  (state: IMobileMenuState, action): IMobileMenuState;
}

const mobileMenuReducer: IMobileMenuReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TOGGLE:
      return { ...state, isOpened: action.payload };
    default:
      return state;
  }
};

export default mobileMenuReducer;
