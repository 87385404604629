import { SW } from '@types';
import * as TYPES from './constants';

interface IProfileLocationState {
  isFetching: boolean;
  isPatching: boolean;
  isFetched: boolean;
  geo_areas: SW.Models.IGeoArea[];
}

const initialState: IProfileLocationState = {
  isFetching: false,
  isPatching: false,
  isFetched: false,
  geo_areas: [],
};

interface IProfileLocationReducer {
  (state: IProfileLocationState, action): IProfileLocationState;
}

const profileLocationReducer: IProfileLocationReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case TYPES.FETCH_SUCCESS:
      return {
        ...state,
        geo_areas: payload,
        isFetching: false,
        isFetched: true,
      };

    case TYPES.REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
      };

    case TYPES.PATCH:
      return {
        ...state,
        isPatching: true,
      };

    case TYPES.CREATE_SUCCESS:
      return {
        ...state,
        geo_areas: [...state.geo_areas, payload],
        isPatching: false,
      };
    case TYPES.UPDATE_SUCCESS:
      return {
        ...state,
        geo_areas: state.geo_areas.map((geo_area) =>
          geo_area.id === payload.id ? payload : geo_area
        ),
        isPatching: false,
      };
    case TYPES.DELETE_SUCCESS:
      return {
        ...state,
        geo_areas: state.geo_areas.filter(
          (geo_area) => geo_area.id !== payload
        ),
        isPatching: false,
      };

    default:
      return state;
  }
};

export default profileLocationReducer;
