import { combineReducers } from 'redux';

import * as TYPES from './constants';

interface IAppsParamsState {
  sort: 'date' | 'status';
  filters: any[];
  companies: any[];
  limit: number;
  skip: number;
}

const paramsInitialState: IAppsParamsState = {
  sort: 'date',
  filters: [],
  companies: [],
  limit: 11,
  skip: 0,
};

const prepareState = (array, item) => {
  if (array.includes(item)) {
    const index = array.indexOf(item);
    array.splice(index, 1);
    return array;
  }
  array.push(item);
  return array;
};

interface IAppsParamsReducer {
  (state: IAppsParamsState, action): IAppsParamsState;
}

const paramsReducer: IAppsParamsReducer = (
  state = paramsInitialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.SET_SORT:
      return { ...state, sort: payload, skip: 0 };

    case TYPES.SET_FILTER:
      return {
        ...state,
        filters: prepareState(state.filters, payload),
        skip: 0,
      };

    case TYPES.SET_COMPANIES:
      return {
        ...state,
        companies: payload,
        skip: 0,
      };

    case TYPES.SET_INITIAL_PARAMS:
      return {
        ...state,
        companies: payload.companies || [],
        filters: payload.filters || [],
        sort: payload.sort || 'date',
      };

    case TYPES.SET_LIMIT_N_SKIP:
      return { ...state, skip: state.skip + state.limit };

    default:
      return state;
  }
};

interface IAppsListingState {
  fetching: boolean;
  initial: boolean;
  fetchingMore: boolean;
  error: any;
  apps: any[];
  filters: {
    company: any[];
    status: any[];
  };
  matched_count: number;
  total_count: number;
}

const listingInitialState: IAppsListingState = {
  fetching: false,
  initial: false,
  fetchingMore: false,
  error: null,
  apps: [],
  filters: {
    company: [],
    status: [],
  },
  matched_count: 0,
  total_count: 0,
};

interface IAppsListingReducer {
  (state: IAppsListingState, action): IAppsListingState;
}

const listingReducer: IAppsListingReducer = (
  state = listingInitialState,
  action
) => {
  switch (action.type) {
    case TYPES.FETCH_APPS:
      return { ...state, fetching: true };
    case TYPES.FETCH_APPS_SUCCESS:
      return {
        ...state,
        fetching: false,
        initial: state.initial === false ? true : state.initial,
        ...action.payload,
      };
    case TYPES.FETCH_APPS_ERROR:
      return { ...state, fetching: false };

    case TYPES.FETCH_MORE_APPS:
      return { ...state, fetchingMore: true };

    case TYPES.FETCH_MORE_APPS_SUCCESS:
      return {
        ...state,
        fetchingMore: false,
        total_count: action.payload.total_count,
        matched_count: action.payload.matched_count,
        apps: state.apps.concat(action.payload.apps),
      };

    case TYPES.FETCH_MORE_APPS_ERROR:
      return { ...state, fetchingMore: false };

    default:
      return state;
  }
};

interface IAppsRecentState {
  fetching: boolean;
  creating: boolean;
  app: any;
}

const recentInitialState = {
  fetching: false,
  creating: false,
  app: null,
};

interface IAppsRecentReducer {
  (state: IAppsRecentState, action): IAppsRecentState;
}

const recentReducer: IAppsRecentReducer = (
  state = recentInitialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH_RECENT_APP:
      return { ...state, fetching: true };

    case TYPES.FETCH_RECENT_APP_SUCCESS:
      return { ...state, fetching: false, ...payload };

    case TYPES.FETCH_RECENT_APP_ERROR:
      return { ...state, fetching: false, ...payload };

    default:
      return state;
  }
};

export default combineReducers({
  params: paramsReducer,
  listing: listingReducer,
  recent: recentReducer,
});
