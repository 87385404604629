import React, { useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from '../../store';
import UrlManager from './UrlManager';
import UrlProviderContext from './UrlProviderContext';
import { useRouterRegistry } from '../RouterRegistry';

const UrlProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const routerRegistry = useRouterRegistry();

  const historyRef = useRef(history);
  const locationRef = useRef(location);
  const dispatchRef = useRef(dispatch);
  const routerRegistryRef = useRef(routerRegistry);

  historyRef.current = history;
  locationRef.current = location;
  dispatchRef.current = dispatch;
  routerRegistryRef.current = routerRegistry;

  const urlManager = useMemo(
    () =>
      new UrlManager(historyRef, locationRef, dispatchRef, routerRegistryRef),
    [history, location, dispatch, routerRegistry]
  );

  return (
    <UrlProviderContext.Provider value={urlManager}>
      {children}
    </UrlProviderContext.Provider>
  );
};

export default UrlProvider;
