import * as TYPES from './constants';

interface IProfileDetailsState {
  isFetching: boolean;
  isPatching: boolean;
  isFetched: boolean;
  email: string;
  first_name: string;
  last_name: string;
  education_id: number;
  last_salary_id: number;
}

const initialState: IProfileDetailsState = {
  isFetching: false,
  isPatching: false,
  isFetched: false,
  email: null,
  first_name: null,
  last_name: null,
  education_id: null,
  last_salary_id: null,
};

interface IProfileDetailsReducer {
  (state: IProfileDetailsState, action): IProfileDetailsState;
}

const profileDetailsReducer: IProfileDetailsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case TYPES.FETCH_SUCCESS:
      return {
        ...state,
        ...payload.personal_details,
        isFetching: false,
        isFetched: true,
      };

    case TYPES.REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
      };

    case TYPES.PATCH:
      return {
        ...state,
        isPatching: true,
      };

    case TYPES.PATCH_SUCCESS:
      return {
        ...state,
        ...payload.personal_details,
        isPatching: false,
      };

    default:
      return state;
  }
};

export default profileDetailsReducer;
