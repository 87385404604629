import React from 'react';
import { Link } from '../../partials/Router';
import * as dialog from '../../../utils/dialog';
import styles from './ConsumerRequestPage.pcss';

const confirmationDescription = (isAuthorized) => (
  <div className={styles.confirmDeactivationContainer}>
    <p>Before you deactivate your account, please note:</p>
    <ul>
      <li>
        <p>You will no longer be able to access this account.</p>
        <p>
          <b>
            We won{"'"}t be able to restore your account once you{"'"}ve deleted
            it.
          </b>
        </p>
      </li>
      <li>
        <p>
          <b>
            You don{"'"}t need to delete your account to stop getting emails
            from us.
          </b>
        </p>
        <p>
          The{' '}
          {isAuthorized ? (
            <Link to="/profile/communication-preferences/job-alerts">
              Manage Alerts
            </Link>
          ) : (
            'Manage Alerts'
          )}{' '}
          page allows you to decide what emails, if any, you receive.
        </p>
      </li>
    </ul>
  </div>
);

const confirmAccountDeactivation = (isAuthorized, successCallback) => {
  return dialog
    .confirm({
      title: 'Are you sure?',
      subtitle: confirmationDescription(isAuthorized),
      acceptButton: 'Deactivate Account',
      declineButton: 'Cancel',
    })
    .then(successCallback)
    .catch(() => {});
};

export default confirmAccountDeactivation;
