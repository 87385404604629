export const ACTION_KEY = 'reg';

export const REG_SIGN_UP = `${ACTION_KEY}/REG_SIGN_UP`;
export const REG_SIGN_UP_SUCCESS = `${ACTION_KEY}/REG_SIGN_UP_SUCCESS`;
export const REG_PART_SIGN_UP_SUCCESS = `${ACTION_KEY}/REG_PART_SIGN_UP_SUCCESS`;
export const REG_SIGN_UP_ERROR = `${ACTION_KEY}/REG_SIGN_UP_ERROR`;

export const REG = `${ACTION_KEY}/REG`;
export const REG_SUCCESS = `${ACTION_KEY}/REG_SUCCESS`;
export const REG_ERROR = `${ACTION_KEY}/REG_ERROR`;
