import { createContext } from 'react';

export interface IUserAgentContext {
  userAgent?: string;
}

const defaultUserAgentContext: IUserAgentContext = {};

const UserAgentContext = createContext<IUserAgentContext>(
  defaultUserAgentContext
);

export default UserAgentContext;
