import { combineReducers } from 'redux';

import { ACTION_KEY as ALERT_KEY } from './Alert/constants';
import alertReducer from './Alert/reducer';
import { ACTION_KEY as ATS_KEY } from './ATSWidget/constants';
import atsReducer from './ATSWidget/reducer';
import { ACTION_KEY as NOTIFICATIONS_ACTION_KEY } from '../../layouts/Base/FlashNotifications/redux/constants';
import notificationsReducer from '../../layouts/Base/FlashNotifications/redux/reducer';
import { ACTION_KEY as OFFERS_KEY } from './Offers/constants';
import offersReducer from './Offers/reducer';
import { ACTION_KEY as PIXELS_KEY } from '../../layouts/Base/Pixels/redux/constants';
import pixelsReducer from '../../layouts/Base/Pixels/redux/reducer';
import { ACTION_KEY as PROFILE_KEY } from './profile/constants';
import profileReducer from './profile/reducer';

export default combineReducers({
  [ALERT_KEY]: alertReducer,
  [ATS_KEY]: atsReducer,
  [NOTIFICATIONS_ACTION_KEY]: notificationsReducer,
  [OFFERS_KEY]: offersReducer,
  [PIXELS_KEY]: pixelsReducer,
  [PROFILE_KEY]: profileReducer,
});
