import React, { useEffect, useState, useRef } from 'react';

import { useSelector, useDispatch } from '../../store';
import AppMetadataContext from './Context';
import { ACTION_KEY } from './constants';
import updateAppMetadataAction from './actions';

const AppMetadataProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const isSsrUsed = useSelector((state) => state[ACTION_KEY].isSsrUsed);
  const renderedBy = useSelector((state) => state[ACTION_KEY].renderedBy);
  const hepldeskRef = useRef<HTMLDivElement>();

  const [activeChunksFetching, setActiveChunksFetching] = useState<number>(0);
  const handleChunksFetching = (action: Promise<any>) => {
    setActiveChunksFetching((i) => i + 1);
    action.finally(() => {
      setActiveChunksFetching((i) => i - 1);
    });
  };

  useEffect(() => {
    dispatch(updateAppMetadataAction());
  });

  return (
    <AppMetadataContext.Provider
      value={{
        isChunksFetching: activeChunksFetching !== 0,
        trackChunksFetching: handleChunksFetching,
        isSsrUsed,
        renderedBy,
        hepldeskRef,
      }}
    >
      {children}
    </AppMetadataContext.Provider>
  );
};

export default AppMetadataProvider;
