import React from 'react';

class StaticLayer extends React.PureComponent {
  public static ID = 'static';

  public render() {
    const { children } = this.props;

    return <div id={StaticLayer.ID}>{children}</div>;
  }
}

export default StaticLayer;
