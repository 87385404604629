import React from 'react';
import classNames from 'classnames/bind';

import Loader from '../Loader';

import s from './LoaderComponent.pcss';

const cx = classNames.bind(s);

interface ILoaderComponentProps {
  color?: string;
  indicator?: boolean;
  extend?: boolean;
  size?: string;
  background?: string;
  isPageLoader?: boolean;
}

const LoaderComponent: React.FC<ILoaderComponentProps> = ({
  background,
  indicator,
  extend,
  color,
  size = '.5',
  isPageLoader,
}) =>
  indicator && (
    <div
      className={cx(s.loaderContainer, {
        [s.extendStyles]: extend,
        [s.loaderPage]: isPageLoader,
      })}
      style={{ background }}
    >
      <Loader color={color} size={size} />
    </div>
  );

LoaderComponent.defaultProps = {
  color: '#142d61',
  indicator: false,
  extend: false,
  size: '.5',
  background: 'rgba(255, 255, 255, 0.75)',
  isPageLoader: false,
};

export default LoaderComponent;
