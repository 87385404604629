import type { Breadcrumb, BreadcrumbHint } from '@sentry/types';
import type { BreadcrumbFetchHint } from './types';

import { tryParseJson } from './utils';

interface IEnhanceFetch {
  (breadcrumb: Breadcrumb, hint: BreadcrumbHint): Breadcrumb;
}

const enhanceFetch: IEnhanceFetch = (breadcrumb, hint) => {
  const { input, response } = hint as BreadcrumbFetchHint;
  const [url, request] = input;

  let extraData;
  if (request) {
    if (request.body instanceof FormData) {
      const body = Array.from(request.body.entries()).reduce(
        (acc, [key, val]) => ({ ...acc, [key]: val }),
        {}
      );
      extraData = {
        request: body,
      };
    } else if (request.body) {
      const body = tryParseJson(request.body);
      extraData = {
        request: JSON.stringify(body),
      };
    }
  }

  return {
    ...breadcrumb,
    category: 'fetch.ext',
    message: `${(request && request.method) || 'GET'} ${url}${
      response?.status ? ` [${response.status}]` : ''
    }`,
    data: {
      ...breadcrumb.data,
      ...extraData,
    },
  };
};

export default enhanceFetch;
