import { useEffect, EffectCallback, DependencyList } from 'react';

function useEffectIf(
  effect: EffectCallback,
  conditionDeps: DependencyList = [],
  extraDeps: DependencyList = []
): void {
  useEffect(() => {
    if (conditionDeps.reduce((state, condition) => state && condition, true)) {
      return effect();
    }
    return () => {};
  }, [...conditionDeps, ...extraDeps]);
}

export default useEffectIf;
