import type { SW } from '@types';
import type { IHttpClient } from 'context/withHttpClient';

import { fetchCompletenessAction } from '../../../../../partials/widgets/profile/actions';

import * as TYPES from './constants';

const fetchProfileDetails = () => ({
  type: TYPES.FETCH,
});
const fetchProfileDetailsSuccess = (params) => ({
  type: TYPES.FETCH_SUCCESS,
  payload: params,
});
const requestError = (params) => ({
  type: TYPES.REQUEST_ERROR,
  payload: params,
});
const patchProfileDetails = () => ({
  type: TYPES.PATCH,
});
const patchProfileDetailsSuccess = (params) => ({
  type: TYPES.PATCH_SUCCESS,
  payload: params,
});

interface IFetchProfileDetailsAction {
  (httpClient: IHttpClient): SW.Redux.ThunkAction;
}

export const fetchProfileDetailsAction: IFetchProfileDetailsAction = (
  httpClient
) => (dispatch) => {
  dispatch(fetchProfileDetails());

  httpClient
    .get('profile/personal_details')
    .then((response) => {
      if (response.success) {
        dispatch(fetchProfileDetailsSuccess(response));
        return response;
      }
      return Promise.reject(response);
    })
    .catch((error) => {
      dispatch(requestError(error));
    });
};

interface IPatchParams {
  email: string;
  first_name: string;
  last_name: string;
  education_id: number;
  last_salary_id: number;
}

interface IPatchProfileDetailsAction {
  (
    params: IPatchParams,
    httpClient: IHttpClient,
    openPopup
  ): SW.Redux.ThunkAction<Promise<void>>;
}

export const patchProfileDetailsAction: IPatchProfileDetailsAction = (
  params,
  httpClient,
  openPopup
) => (dispatch) => {
  dispatch(patchProfileDetails());

  return httpClient
    .patch('profile/personal_details', { personal_details: params })
    .then((response) => {
      if (response.success) {
        dispatch(patchProfileDetailsSuccess(response));
        dispatch(fetchCompletenessAction(httpClient));
        openPopup('offers', 'after-change-profile-personal-details');
        return response;
      }
      dispatch(requestError(response));
      return Promise.reject(response);
    });
};
