import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Alert from '../Alert';
import Confirmation from '../Confirmation';

interface IConfirmProps {
  title: string;
  subtitle?: React.ReactNode;
  acceptButton: string;
  declineButton: string;
}

class Dialog extends React.PureComponent {
  private static instanse = null;

  public static showAlert = ({ title, subtitle, button }) => {
    return new Promise((resolve) => {
      const alertKey = uuidv4();
      const alert = (
        <Alert
          key={alertKey}
          title={title}
          subtitle={subtitle}
          button={button}
          onClick={() => {
            delete Dialog.instanse.state.queue[alertKey];
            Dialog.instanse.setState({
              queue: { ...Dialog.instanse.state.queue },
            });
            resolve(null);
          }}
        />
      );

      Dialog.instanse.setState({
        queue: {
          ...Dialog.instanse.state.queue,
          [alertKey]: alert,
        },
      });
    });
  };

  public static showConfirm = ({
    title,
    subtitle,
    acceptButton,
    declineButton,
  }: IConfirmProps) => {
    return new Promise((resolve, reject) => {
      const alertKey = uuidv4();
      const alert = (
        <Confirmation
          key={alertKey}
          title={title}
          subtitle={subtitle}
          acceptButton={acceptButton}
          declineButton={declineButton}
          onAccept={() => {
            delete Dialog.instanse.state.queue[alertKey];
            Dialog.instanse.setState({
              queue: { ...Dialog.instanse.state.queue },
            });
            resolve(null);
          }}
          onDecline={() => {
            delete Dialog.instanse.state.queue[alertKey];
            Dialog.instanse.setState({
              queue: { ...Dialog.instanse.state.queue },
            });
            reject();
          }}
        />
      );

      Dialog.instanse.setState({
        queue: {
          ...Dialog.instanse.state.queue,
          [alertKey]: alert,
        },
      });
    });
  };

  public state = {
    queue: {},
  };

  public constructor(props) {
    super(props);
    Dialog.instanse = this;
  }

  public render() {
    const { queue } = this.state;
    return Object.values(queue);
  }
}

export default Dialog;
