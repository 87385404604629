import type { Breadcrumb, BreadcrumbHint } from '@sentry/types';
import enhanceHttp from './http';

interface IEnhanceBreadcrumb {
  (breadcrumb: Breadcrumb, hint: BreadcrumbHint): Breadcrumb;
}

const enhanceByType: IEnhanceBreadcrumb = (breadcrumb, hint) => {
  switch (breadcrumb.type) {
    case 'http':
      return enhanceHttp(breadcrumb, hint);
    default:
      return breadcrumb;
  }
};

const enhance: IEnhanceBreadcrumb = (breadcrumb, hint) => {
  if (breadcrumb) {
    return enhanceByType(breadcrumb, hint);
  }
  return breadcrumb;
};

export default enhance;
