import React from 'react';

import Link from '../Link';
import { useUrlMethods } from '../../../../context/withUrlMethods';

interface IPopupLinkProps {
  className?: string;
  id?: string;
  title?: string;
  name?: string;
  value?: string | number;
  additionalQueryParams?: object;
  onClick?(): void;
  closePopup?: string;
  disabled?: boolean;
}

const PopupLink: React.FC<IPopupLinkProps> = ({
  id,
  title,
  children,
  className,
  name,
  value = '',
  onClick,
  additionalQueryParams,
  closePopup,
  disabled,
}) => {
  const { buildPopupLocation } = useUrlMethods();

  return (
    <Link
      id={id}
      className={className}
      to={buildPopupLocation(name, value, closePopup, additionalQueryParams)}
      onClick={onClick}
      disabled={disabled}
    >
      {children || title}
    </Link>
  );
};

PopupLink.defaultProps = {
  disabled: false,
};

export default PopupLink;
