import React from 'react';
import cn from 'classnames';
import { FaTimes } from 'react-icons/fa';

import SafeContent from '../../../../partials/SafeContent';

import styles from './Notice.pcss';

interface INoticeProps {
  content: string;
  onClose?(): void;
  type: 'error' | 'notice' | 'success';
}

const Notice: React.FC<INoticeProps> = ({ content, onClose, type }) => (
  <div className={cn(styles.notice, styles[`notice_${type}`])}>
    {onClose && <FaTimes className={styles.close} onClick={onClose} />}
    <SafeContent className={styles.content}>{content}</SafeContent>
  </div>
);

Notice.defaultProps = {
  onClose: null,
};

export default Notice;
