import { clearSessionAction } from '../../../components/popups/authentication/session/actions';
import { pixelAction } from '../../../components/layouts/Base/Pixels';
import { pushNotificationAction } from '../../../components/layouts/Base/FlashNotifications';

import { SETUP_GTM } from '../../../store/middlewares/gtm';

const responseMiddleware = (dispatch, changeLocation, response, json) => {
  if (response.status === 401) {
    dispatch(pushNotificationAction({ notice: 'Your session is expired' }));
    setTimeout(() => {
      dispatch(clearSessionAction());
    }, 1000);
  }

  if (json.gtm_meta) {
    dispatch({ type: SETUP_GTM, payload: json.gtm_meta });
  }

  if (json.pixels) {
    dispatch(pixelAction(json.pixels));
  }

  if (json.flashes) {
    json.flashes.forEach((flash) => dispatch(pushNotificationAction(flash)));
  }

  if (json.redirect_to) {
    const redirectTo = new URL(json.redirect_to, process.env.HOST);
    if (
      redirectTo.origin === process.env.HOST &&
      !/\/portal$/.test(json.redirect_to)
    ) {
      changeLocation({
        pathname: redirectTo.pathname,
        search: redirectTo.search,
      });
    } else {
      window.location.href = json.redirect_to;
    }
  }
};

export default responseMiddleware;
