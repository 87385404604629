import React from 'react';
import HttpClientContext from './HttpClientContext';
import { IHttpClient } from './HttpClient';

export interface IWithHttpClientProps {
  httpClient: IHttpClient;
}

interface IWithHttpClient {
  <T extends IWithHttpClientProps>(
    Component: React.ComponentType<T>
  ): React.ComponentType<Omit<T, keyof IWithHttpClientProps>>;
}

const withHttpClient: IWithHttpClient = (Component) => {
  const Hoc = (props) => (
    <HttpClientContext.Consumer>
      {(httpClient) => <Component {...props} httpClient={httpClient} />}
    </HttpClientContext.Consumer>
  );

  Hoc.displayName = `WithHttpClient(${
    Component.displayName || Component.name
  })`;
  return Hoc;
};

export default withHttpClient;
