import React, { useEffect } from 'react';
import { Switch as OriginSwitch } from 'react-router-dom';
import type { SwitchProps } from 'react-router-dom';
import { ILazyPageRouteProps } from '../ILazyRouteProps';
import { useRouterRegistry } from '../../../../context/RouterRegistry';

interface IPageSwitchProps extends SwitchProps {
  children: React.ReactElement<ILazyPageRouteProps>[];
}

const PageSwitch: React.FC<IPageSwitchProps> = (props) => {
  const { children } = props;
  const routerRegistry = useRouterRegistry();

  useEffect(() => {
    React.Children.forEach(children, (child) => {
      const { path, component } = child.props;
      if (component?.preload) {
        routerRegistry.registerPage(path, child.props);
      }
    });
  }, []);

  return <OriginSwitch {...props} />;
};

export default PageSwitch;
