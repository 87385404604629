import React from 'react';
import { Redirect } from 'react-router-dom';

import { useSelector } from '../../../store';
import Route from './Route';
import type { IRouteProps } from './Route';

interface IPrivateRouteProps extends IRouteProps {
  isRestorable?: boolean;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  component: Component,
  isRestorable,
  ...rest
}) => {
  const isUserAuthorized = useSelector(
    (state) => !!state.auth.session.current_user
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {
                ...(isRestorable ? { backToPrivate: props.location } : null),
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.defaultProps = {
  isRestorable: true,
};

export default PrivateRoute;
