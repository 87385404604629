import * as TYPES from './constants';

interface IJobState {
  fetching: boolean;
  job: {
    position: string;
  };
}

const initialState: IJobState = {
  fetching: false,
  job: {
    position: '',
  },
};

interface IJobReducer {
  (state: IJobState, action): IJobState;
}

const jobReducer: IJobReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.FETCH_JOBS:
      return { ...state, fetching: true };

    case TYPES.FETCH_JOBS_SUCCESS:
      return { ...state, fetching: false, ...payload };

    case TYPES.FETCH_JOBS_ERROR:
      return { ...state, fetching: false };

    default:
      return state;
  }
};

export default jobReducer;
