import type { Breadcrumb, BreadcrumbHint } from '@sentry/types';

import captureEventOnFetch from './fetch';

interface ICaptureEventByHttpCategory {
  (breadcrumb: Breadcrumb, hint: BreadcrumbHint): void;
}

const captureEventByHttpCategory: ICaptureEventByHttpCategory = (
  breadcrumb,
  hint
) => {
  switch (breadcrumb.category) {
    case 'fetch.ext': {
      captureEventOnFetch(breadcrumb, hint);
      break;
    }
    default:
  }
};

export default captureEventByHttpCategory;
