import React, { useState, useCallback } from 'react';
import { StaticLayer } from '../../components/layouts/Base/Layer';
import LayerManagerContext from './Context';
import Scroll from '../../utils/scroll';

const LayerManagerProvider = (props) => {
  const [layersNumber, setLayersNumber] = useState(0);

  const generateId = () => {
    const nextLayerNumber = layersNumber + 1;
    setLayersNumber(nextLayerNumber);
    return `layer-${nextLayerNumber}`;
  };

  const handleAdd = useCallback((ref: React.RefObject<HTMLElement>) => {
    const { nextSibling, previousSibling } = ref.current;
    const nextHTMLSibling = nextSibling as HTMLElement;

    if (nextHTMLSibling?.id !== StaticLayer.ID) {
      Scroll.disableLayerScroll(ref.current);
    } else if (previousSibling) {
      Scroll.disableLayerScroll(previousSibling);
    }
  }, []);

  const handleRemove = useCallback((ref: React.RefObject<HTMLElement>) => {
    const { nextSibling, previousSibling } = ref.current;
    const nextHTMLSibling = nextSibling as HTMLElement;
    if (!(nextHTMLSibling?.id !== StaticLayer.ID) && previousSibling) {
      Scroll.enableLayerScroll(previousSibling);
    }
  }, []);

  return (
    <LayerManagerContext.Provider
      value={{
        generateId,
        onAdd: handleAdd,
        onRemove: handleRemove,
      }}
    >
      {props.children}
    </LayerManagerContext.Provider>
  );
};

export default LayerManagerProvider;
