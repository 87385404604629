import React from 'react';
import { IHttpClient } from './HttpClient';

const INITIAL_VALUE: IHttpClient = {
  file() {
    throw new Error(`not implemented`);
  },
  post() {
    throw new Error(`not implemented`);
  },
  put() {
    throw new Error(`not implemented`);
  },
  patch() {
    throw new Error(`not implemented`);
  },
  delete() {
    throw new Error(`not implemented`);
  },
  get() {
    throw new Error(`not implemented`);
  },
};

const HttpClientContext = React.createContext<IHttpClient>(INITIAL_VALUE);

export default HttpClientContext;
