import React from 'react';
import { connect } from 'react-redux';

import styles from './Pixels.pcss';

interface IPixelsProps {
  pixels?: any[];
}

const Pixels: React.FC<IPixelsProps> = ({ pixels }) => (
  <div className={styles.container}>
    {pixels.map((pixel, key) =>
      // TODO: Eslint error should be fixed by refactoring (Denis Shvets)
      // eslint-disable-next-line react/no-array-index-key
      React.createElement(pixel.tag, { key, ...pixel })
    )}
  </div>
);

const mapStateToProps = ({ widgets }) => ({
  pixels: widgets.pixels.pixels,
});

export default connect(mapStateToProps)(Pixels);
