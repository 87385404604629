import React from 'react';
import OnBoardingContext from './OnBoardingContext';

const withOnBoarding = (Component) => {
  const Hoc = (props) => (
    <OnBoardingContext.Consumer>
      {({ startOnBoarding, stopOnBoarding, isOnBoardingActive }) => (
        <Component
          {...props}
          {...{ startOnBoarding, stopOnBoarding, isOnBoardingActive }}
        />
      )}
    </OnBoardingContext.Consumer>
  );

  Hoc.displayName = `WithOnBoarding(${
    Component.displayName || Component.name
  })`;
  return Hoc;
};

export default withOnBoarding;
