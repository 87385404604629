import { ADD_PIXEL } from './constants';

interface IPixelsState {
  pixels: any[];
}

const initialState: IPixelsState = {
  pixels: [],
};

interface IPixelsReducer {
  (state: IPixelsState, action): IPixelsState;
}

const pixelsReducer: IPixelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PIXEL:
      return { ...state, pixels: [...state.pixels, ...action.payload] };

    default:
      return state;
  }
};

export default pixelsReducer;
