import 'normalize.css/normalize.css';
import 'react-widgets/dist/css/react-widgets.css';
import 'nprogress/nprogress.css';
import '../components/assets/fonts/fonts.pcss';
import '../global.pcss';
import '../widgets.pcss';

import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import React from 'react';
import { Frontload } from 'react-frontload';
import { HelmetProvider } from 'react-helmet-async';

import App from '../App';

const initClient = (store, browserHistory) => (
  <HelmetProvider>
    <Frontload noServerRender>
      <Provider store={store}>
        <ConnectedRouter history={browserHistory}>
          <App />
        </ConnectedRouter>
      </Provider>
    </Frontload>
  </HelmetProvider>
);

export default initClient;
