import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import type { History } from 'history';

import { ACTION_KEY as LANDING_KEY } from './components/pages/Landing/constants';
import LandingReducer from './components/pages/Landing/reducer';
import { ACTION_KEY as COLLAPSABLE_KEY } from './components/partials/Collapsable/constants';
import CollapsableReducer from './components/partials/Collapsable/reducer';
import { ACTION_KEY as MOBILE_MENU_KEY } from './components/partials/Header/MobileHeader/MobileMenu/constants';
import MobileMenuReducer from './components/partials/Header/MobileHeader/MobileMenu/reducer';
import { ACTION_KEY as AUTH_KEY } from './components/popups/authentication/constants';
import AuthReducer from './components/popups/authentication/reducer';
import { ACTION_KEY as APPLICATION_LIST_KEY } from './components/pages/Apps/constants';
import ApplicationListReducer from './components/pages/Apps/reducer';
import { ACTION_KEY as APPLICATION_DETAILS_KEY } from './components/pages/App/constants';
import ApplicationDetailsReducer from './components/pages/App/reducer';
import { ACTION_KEY as PROFILE_KEY } from './components/pages/Profile/constants';
import ProfileReducer from './components/pages/Profile/reducer';
import { ACTION_KEY as OFFERS_KEY } from './components/popups/Offers/constants';
import OffersReducer from './components/popups/Offers/reducer';
import { ACTION_KEY as ADDITIONAL_QUESTIONS_KEY } from './components/popups/AdditionalQuestions/constants';
import AdditionalQuestionsReducer from './components/popups/AdditionalQuestions/reducer';
import { ACTION_KEY as AUTO_ACCEPT_OFFERS_KEY } from './components/popups/AutoAcceptOffers/constants';
import AutoAcceptOffersPopupReducer from './components/popups/AutoAcceptOffers/reducer';
import { ACTION_KEY as EXPRESS_APPLY_KEY } from './components/popups/ExpressApply/constants';
import ExpressApplyPopupReducer from './components/popups/ExpressApply/reducer';
import { ACTION_KEY as AUTO_STATUS_UPDATE_KEY } from './components/popups/AutoStatusUpdates/constants';
import AutoStatusUpdatesReducer from './components/popups/AutoStatusUpdates/reducer';
import { ACTION_KEY as REG_KEY } from './components/pages/Reg/constants';
import RegReducer from './components/pages/Reg/reducer';
import { ACTION_KEY as WIDGETS_KEY } from './components/partials/widgets/constants';
import WidgetsReducer from './components/partials/widgets/reducer';
import { ACTION_KEY as VIDEOS_KEY } from './components/pages/CareerResources/constants';
import VideosReducer from './components/pages/CareerResources/reducer';
import { ACTION_KEY as LOAD_SCRIPT_KEY } from './common/hooks/useScript/constants';
import LoadScriptReducer from './common/hooks/useScript/reducer';
import { ACTION_KEY as JOB_KEY } from './components/pages/Job/constants';
import JobReducer from './components/pages/Job/reducer';
import { ACTION_KEY as JOB_ALERT_KEY } from './components/pages/JobAlert/constants';
import JobAlertReducer from './components/pages/JobAlert/reducer';
import { ACTION_KEY as JOBS_UA_KEY } from './components/pages/JobsUa/constants';
import JobsUAReducer from './components/pages/JobsUa/reducer';
import { ACTION_KEY as OFFER_PAGE_KEY } from './components/pages/Offer/constants';
import OfferPageReducer from './components/pages/Offer/reducer';
import { ACTION_KEY as EXPRESS_APPLY_JOBS_POPUP_KEY } from './components/popups/ExpressApplyJobs/constants';
import ExpressApplyJobsPopupReducer from './components/popups/ExpressApplyJobs/reducer';
import { ACTION_KEY as QUICK_OFFERS_KEY } from './components/popups/QuickOffers/constants';
import QuickOffersReducer from './components/popups/QuickOffers/reducer';
import { ACTION_KEY as COMPANY_PAGE_KEY } from './components/pages/Company/constants';
import CompanyPageReducer from './components/pages/Company/reducer';
import { ACTION_KEY as TOP_COMPANIES_HIRING_PAGE_KEY } from './components/pages/TopCompaniesHiring/constants';
import TopCompaniesHiringPageReducer from './components/pages/TopCompaniesHiring/reducer';
import { ACTION_KEY as APP_METADATA_KEY } from './context/AppMetadata/constants';
import appMetadataReducer from './context/AppMetadata/reducer';
import { ACTION_KEY as SW_FORM_KEY } from './components/partials/form/constants';
import swFormReducer from './components/partials/form/reducer';
import { ACTION_KEY as SEARCH_API_KEY } from './components/partials/Search/useSearchApi/constants';
import searchApiReducer from './components/partials/Search/useSearchApi/reducer';

const reducersCombination = {
  form: formReducer,
  [LANDING_KEY]: LandingReducer,
  [AUTO_STATUS_UPDATE_KEY]: AutoStatusUpdatesReducer,
  [AUTO_ACCEPT_OFFERS_KEY]: AutoAcceptOffersPopupReducer,
  [EXPRESS_APPLY_KEY]: ExpressApplyPopupReducer,
  [OFFERS_KEY]: OffersReducer,
  [QUICK_OFFERS_KEY]: QuickOffersReducer,
  [COLLAPSABLE_KEY]: CollapsableReducer,
  [MOBILE_MENU_KEY]: MobileMenuReducer,
  [APPLICATION_LIST_KEY]: ApplicationListReducer,
  [APPLICATION_DETAILS_KEY]: ApplicationDetailsReducer,
  [PROFILE_KEY]: ProfileReducer,
  [ADDITIONAL_QUESTIONS_KEY]: AdditionalQuestionsReducer,
  [JOBS_UA_KEY]: JobsUAReducer,
  [EXPRESS_APPLY_JOBS_POPUP_KEY]: ExpressApplyJobsPopupReducer,
  [JOB_ALERT_KEY]: JobAlertReducer,
  [JOB_KEY]: JobReducer,
  [AUTH_KEY]: AuthReducer,
  [VIDEOS_KEY]: VideosReducer,
  [WIDGETS_KEY]: WidgetsReducer,
  [REG_KEY]: RegReducer,
  [LOAD_SCRIPT_KEY]: LoadScriptReducer,
  [OFFER_PAGE_KEY]: OfferPageReducer,
  [COMPANY_PAGE_KEY]: CompanyPageReducer,
  [TOP_COMPANIES_HIRING_PAGE_KEY]: TopCompaniesHiringPageReducer,
  [APP_METADATA_KEY]: appMetadataReducer,
  [SW_FORM_KEY]: swFormReducer,
  [SEARCH_API_KEY]: searchApiReducer,
};

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    ...reducersCombination,
  });

export default createRootReducer;
