import findIndex from 'lodash/findIndex';
import * as TYPES from './constants';

interface IAutoStatusUpdatesState {
  fetching: boolean;
  patching: boolean;
  default_aggregation_credentials: boolean;
  ats_apps: any[];
  ats_setting: {
    default_aggregation_credentials: any;
    sms_status_updates: any;
    phone: any;
    position_status_type: any;
    position_status_apps: any[];
    ats_credentials: any[];
    single_position_status_app: any;
    single_ats_credential: any;
    companies: any;
  };
  error: any;
}

const initialState: IAutoStatusUpdatesState = {
  fetching: false,
  patching: false,
  default_aggregation_credentials: false,
  ats_apps: [],
  ats_setting: {
    default_aggregation_credentials: undefined,
    sms_status_updates: undefined,
    phone: undefined,
    position_status_type: undefined,
    position_status_apps: [],
    ats_credentials: [],
    single_position_status_app: null,
    single_ats_credential: null,
    companies: null,
  },
  error: null,
};

interface IAutoStatusUpdateReducer {
  (state: IAutoStatusUpdatesState, action): IAutoStatusUpdatesState;
}

const autoStatusUpdateReducer: IAutoStatusUpdateReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH_ATS_SETTINGS:
      return { ...state, fetching: true };

    case TYPES.FETCH_ATS_SETTINGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        ats_setting: payload.ats_setting,
        default_aggregation_credentials:
          payload.ats_setting.default_aggregation_credentials,
      };

    case TYPES.FETCH_ATS_SETTINGS_ERROR:
      return { ...state, fetching: false, ...payload };

    case TYPES.PATCH_ATS_SETTINGS:
      return { ...state, patching: true };

    case TYPES.PATCH_ATS_SETTINGS_SUCCESS:
      return {
        ...state,
        patching: false,
        ats_setting: payload.ats_setting,
        ats_apps: payload.ats_setting.apps,
      };

    case TYPES.PATCH_ATS_SETTINGS_ERROR:
      return { ...state, patching: false, ...payload };

    case TYPES.UPDATE_ATS_SETTINGS:
      return { ...state, default_aggregation_credentials: payload };

    case TYPES.REMOVE_APP_FROM_ATS:
      if (findIndex(state.ats_apps, { id: payload }) !== -1) {
        state.ats_apps.splice(findIndex(state.ats_apps, { id: payload }), 1);
      }

      return { ...state, ats_apps: [...state.ats_apps] };

    case TYPES.CLEAR_ATS_SETTINGS:
      return initialState;

    default:
      return state;
  }
};

export default autoStatusUpdateReducer;
