import { IHttpClient } from '../../../context/withHttpClient';

const submitConsumerRequest = (
  httpClient: IHttpClient,
  formData: { request: string[] },
  changeLocation: (locatin: object) => void,
  setIsSubmitting: (val: boolean) => void
): Promise<void> => {
  setIsSubmitting(true);
  return httpClient
    .post('consumer_request', formData)
    .then((response) => changeLocation({ pathname: response.redirect }))
    .finally(() => setIsSubmitting(false));
};

export default submitConsumerRequest;
