export default class Size {
  static isMobile() {
    return window.innerWidth < 960;
  }

  static isDesktop() {
    return window.innerWidth > 1024;
  }

  static isMedium() {
    return window.innerWidth < 1024 && window.innerWidth > 768;
  }

  static isSmall() {
    return window.innerWidth < 768 && window.innerWidth > 200;
  }
}
