import { createContext } from 'react';
import type { History, Location, LocationDescriptor } from 'history';

export interface IUrlProviderContext {
  history: History;
  location: Location;
  buildClientPath(path, params?: any): any;
  changeLocation(
    location: LocationDescriptor,
    extendCurrent?: boolean
  ): Promise<void>;
  // TODO[SL]: rework as queryParams: Record<string, any>;
  getQueryParams(): Record<string, any>;
  // TODO[SL]: rework as queryKeys: string[];
  getQueryKeys(): string[];
  changeQueryParams(params: any): any;
  removeQueryParam(key: string): void;
  pushArrayQueryParam(key: string, value: any): any;
  removeArrayQueryParam(key: string, index: any): void;
  isPage(page): any;
  buildPopupLocation(
    openPopupName,
    openPopupValue?,
    closePopupName?,
    additionalQueryParams?: any
  ): any;
  openPopup(
    openPopupName,
    openPopupValue?: any,
    closePopupName?: any,
    additionalQueryParams?: any
  ): any;
  closePopup(closePopupName): any;
}

const throwNotImplemented = () => {
  throw Error(`not implemented`);
};

const defaultUrlProviderContext: IUrlProviderContext = {
  history: null,
  location: null,
  buildClientPath: throwNotImplemented,
  changeLocation: throwNotImplemented,
  getQueryParams: throwNotImplemented,
  getQueryKeys: throwNotImplemented,
  changeQueryParams: throwNotImplemented,
  removeQueryParam: throwNotImplemented,
  pushArrayQueryParam: throwNotImplemented,
  removeArrayQueryParam: throwNotImplemented,
  isPage: throwNotImplemented,
  buildPopupLocation: throwNotImplemented,
  openPopup: throwNotImplemented,
  closePopup: throwNotImplemented,
};

const UrlProviderContext = createContext<IUrlProviderContext>(
  defaultUrlProviderContext
);

export default UrlProviderContext;
