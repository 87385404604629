import { useState } from 'react';

function useWeakMap<K extends object, V>(
  initial: WeakMap<K, V>
): {
  get(key: K): V;
  set(key: K, value: V): WeakMap<K, V>;
  remove(key: K): boolean;
} {
  const [state, setState] = useState<WeakMap<K, V>>(initial);

  const get = (key: K) => state.get(key);
  const set = (key: K, value: V): WeakMap<K, V> => {
    setState(state.set(key, value));
    return state;
  };
  const remove = (key: K): boolean => {
    const result = state.delete(key);
    setState(state);
    return result;
  };

  return { get, set, remove };
}

export default useWeakMap;
