import React from 'react';
import cn from 'classnames';

import styles from './Body.pcss';

interface IBodyProps {
  className?: string;
}

const Body: React.FC<IBodyProps> = ({ children, className }) => (
  <div className={cn(styles.body, className)}>{children}</div>
);

Body.defaultProps = {
  className: undefined,
};

export default Body;
