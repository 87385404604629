export default class Scroll {
  public static disableLayerScroll(element) {
    if (!element) return;

    if (document) {
      this.disableLayerScrollBrowser(element);
    } else {
      this.disableLayerScrollSsr(element);
    }
  }

  public static enableLayerScroll(element) {
    if (!element) return;

    if (document) {
      this.enableLayerScrollBrowser(element);
    } else {
      this.enableLayerScrollSsr(element);
    }
  }

  private static disableLayerScrollBrowser(element) {
    const { body, documentElement } = document;
    Scroll.scrollElement = documentElement.scrollTop ? documentElement : body;
    Scroll.scrollTop = Scroll.scrollElement.scrollTop;

    /* eslint-disable-next-line no-param-reassign */
    element.style.top = `-${Scroll.scrollTop}px`;
    Scroll.scrollElement.scrollTop = 0;
  }

  private static disableLayerScrollSsr(element) {
    /* eslint-disable-next-line no-param-reassign */
    element.style.top = `0`;
  }

  private static enableLayerScrollBrowser(element) {
    /* eslint-disable-next-line no-param-reassign */
    element.style.top = '';
    if (Scroll.scrollElement) {
      Scroll.scrollElement.scrollTop = Scroll.scrollTop;
    }

    Scroll.scrollElement = null;
    Scroll.scrollTop = 0;
  }

  private static enableLayerScrollSsr(element) {
    /* eslint-disable-next-line no-param-reassign */
    element.style.top = '';
  }

  private static scrollElement = null;

  private static scrollTop = 0;
}
