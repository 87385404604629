import type { Store } from 'redux';
import type { History } from 'history';
import initGoogleAnalytic from './googleAnalytics';
import initSentry from './sentry';

const initServices = (store: Store, history: History) => {
  initSentry(store, history);

  if (process.env.GA_ID) {
    initGoogleAnalytic(process.env.GA_ID, store, history);
  }
};

export default initServices;
