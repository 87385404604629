import { SHOW, HIDE } from './constants';

interface ICollapsableState {
  [key: string]: boolean;
}

interface ICollapsableReducer {
  (state: ICollapsableState, action): ICollapsableState;
}

const collapsableReducer: ICollapsableReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW:
      return { ...state, [action.payload]: true };
    case HIDE:
      return { ...state, [action.payload]: false };
    default:
      return state;
  }
};

export default collapsableReducer;
