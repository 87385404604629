import {
  ACCEPT_FAILURE,
  ACCEPT_REQUEST,
  ACCEPT_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  REJECT_FAILURE,
  REJECT_REQUEST,
  REJECT_SUCCESS,
  RESET,
} from './constants';

interface IQuickOffersState {
  isFetching: boolean;
  isSuccessful: boolean | null;
  payload: any[];
}

const initialState = {
  isFetching: false,
  isSuccessful: null,
  payload: [],
};

interface IQuickOffersReducer {
  (state: IQuickOffersState, action): IQuickOffersState;
}

const quickOffersReducer: IQuickOffersReducer = (
  state = initialState,
  { payload, type }
) => {
  switch (type) {
    case ACCEPT_REQUEST:
    case FETCH_REQUEST:
    case REJECT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccessful: payload.success,
        payload: payload.offers.blocks,
      };
    case ACCEPT_SUCCESS:
    case ACCEPT_FAILURE:
    case FETCH_FAILURE:
    case REJECT_SUCCESS:
    case REJECT_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default quickOffersReducer;
