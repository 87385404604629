import React, { useState } from 'react';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import difference from 'lodash/difference';

import useEffectIf from '../../common/hooks/useEffectIf';
import { useUrlMethods } from '../withUrlMethods';
import onBoardingPopupStrategyRules, {
  onBoardingHandlePopupNames,
} from './onBoardingPopupStrategyRules';
import OnBoardingContext from './OnBoardingContext';

const OnBoardingProvider: React.FC = ({ children }) => {
  const [isOnBoardingActive, setOnBoardingActivity] = useState(false);
  const [processedPopupNames, setProcessedPopupNames] = useState<string[]>([]);
  const { getQueryKeys, openPopup } = useUrlMethods();
  const queryKeys = getQueryKeys();

  const openedPopupNames = intersection(queryKeys, onBoardingHandlePopupNames);

  const handleStartOnBoarding = () => setOnBoardingActivity(true);
  const handleStopOnBoarding = () => setOnBoardingActivity(false);

  useEffectIf(
    () => {
      const closedPopupNames = difference(
        processedPopupNames,
        openedPopupNames
      );

      const activeRules = onBoardingPopupStrategyRules.filter((rule) =>
        includes(closedPopupNames, rule.onClosePopupName)
      );

      activeRules.forEach((rule) =>
        rule.action({ openPopup, stopOnBoarding: handleStopOnBoarding })
      );

      setProcessedPopupNames(openedPopupNames);
    },
    [isOnBoardingActive],
    [JSON.stringify(openedPopupNames)]
  );

  return (
    <OnBoardingContext.Provider
      value={{
        startOnBoarding: handleStartOnBoarding,
        stopOnBoarding: handleStopOnBoarding,
        isOnBoardingActive,
      }}
    >
      {children}
    </OnBoardingContext.Provider>
  );
};

export default OnBoardingProvider;
