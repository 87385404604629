import type { IHttpClient } from 'context/withHttpClient';
import { camelizeKeysDeep } from '../../../../../common/helpers/normalize';
import type { IResume, RESUME_CREATE_ACTION_ENUM } from './IResume';

export const normalizeResume = (resume): IResume => {
  if (!resume) return null;

  const normalizedResume = camelizeKeysDeep(resume);
  normalizedResume.updated = new Date(normalizedResume.updated * 1000);
  const { fileName } = normalizedResume;
  normalizedResume.extention = fileName
    .substr(fileName.indexOf('.') + 1)
    .toLowerCase();
  return normalizedResume;
};

export const normalizeResumes = (resumes): IResume[] =>
  resumes.map(normalizeResume);

export const fetchResumes = (httpClient): Promise<IResume[]> =>
  httpClient
    .get('profile/resumes')
    .then((payload) => normalizeResumes(payload.resumes));

export const removeResume = async (httpClient, id: number) =>
  httpClient.delete(`profile/resumes/${id}`);

export const markResumeDefault = async (httpClient, id: number) =>
  httpClient
    .post(`profile/resumes/${id}/mark_as_default`)
    .then(({ resume }) => normalizeResume(resume));

export interface IUrlCreateResumeParams {
  url: string;
  name: string;
  source: string;
}
export interface IFileCreateResumeParams {
  file: File;
  source: string;
}
export interface ITextCreateResumeParams {
  text: string;
  source: string;
}

export type CreateResumeParams =
  | IUrlCreateResumeParams
  | IFileCreateResumeParams
  | ITextCreateResumeParams;

const createUrlResume = (
  httpClient,
  urlParams: IUrlCreateResumeParams,
  createAction: RESUME_CREATE_ACTION_ENUM
) => {
  const data = {
    resume: {
      file_url: urlParams.url,
      file_name: urlParams.name,
      created_via: urlParams.source,
    },
    commit: createAction,
  };
  return httpClient
    .post('profile/resumes', data)
    .then((response) => {
      if (!response.success) {
        return Promise.reject(response);
      }
      return normalizeResume(response.resume);
    })
    .catch(({ errors: { file } = { file: ['Server error, try again'] } }) =>
      Promise.reject(file[0])
    );
};

const createFileResume = (
  httpClient: IHttpClient,
  fileParams: IFileCreateResumeParams,
  createAction: RESUME_CREATE_ACTION_ENUM
) => {
  if (fileParams.file.size <= 0) {
    /* eslint-disable-next-line prefer-promise-reject-errors */
    return Promise.reject(
      'Invalid non-resume file. Please select another option.'
    );
  }

  const formData = new FormData();
  formData.append('resume[file]', fileParams.file);
  formData.append('resume[created_via]', fileParams.source);
  formData.append('commit', createAction);

  return httpClient
    .file('profile/resumes', formData)
    .then((response) => {
      if (!response.success) {
        return Promise.reject(response);
      }
      return normalizeResume(response.resume);
    })
    .catch(({ errors: { file } = { file: ['Server error, try again'] } }) =>
      Promise.reject(file[0])
    );
};

const createTextResume = (
  httpClient,
  textParams: ITextCreateResumeParams,
  createAction: RESUME_CREATE_ACTION_ENUM
) => {
  const data = {
    resume: {
      parsed_text: textParams.text,
      created_via: textParams.source,
    },
    commit: createAction,
  };
  return httpClient
    .post('profile/resumes', data)
    .then((response) => {
      if (!response.success) {
        return Promise.reject(response);
      }
      return normalizeResume(response.resume);
    })
    .catch(
      ({
        errors: { parsed_text } = {
          parsed_text: ['Server error, try again'],
        },
      }) => Promise.reject(parsed_text[0])
    );
};

export const createResume = (
  httpClient,
  params: CreateResumeParams,
  createAction: RESUME_CREATE_ACTION_ENUM
): Promise<IResume> => {
  if (Object.prototype.hasOwnProperty.call(params, 'url')) {
    return createUrlResume(
      httpClient,
      params as IUrlCreateResumeParams,
      createAction
    );
  }
  if (Object.prototype.hasOwnProperty.call(params, 'file')) {
    return createFileResume(
      httpClient,
      params as IFileCreateResumeParams,
      createAction
    );
  }
  if (Object.prototype.hasOwnProperty.call(params, 'text')) {
    return createTextResume(
      httpClient,
      params as ITextCreateResumeParams,
      createAction
    );
  }

  throw new TypeError('unknown params for resume creating');
};
