import React from 'react';
import cn from 'classnames';
import type { LinkProps } from 'react-router-dom';

import styles from './Link.pcss';

interface ILinkProps extends LinkProps {
  className?: string;
  to: string;
  disabled?: boolean;
}

const ExtLink: React.FC<ILinkProps> = (props) => {
  const { className, to, disabled, children, ...restProps } = props;

  return (
    <a
      className={cn(styles.link, className, { [styles.disabled]: disabled })}
      href={to}
      {...restProps}
    >
      {children}
    </a>
  );
};

ExtLink.defaultProps = {
  className: undefined,
  disabled: false,
};

export default ExtLink;
