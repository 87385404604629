import React from 'react';
import cn from 'classnames';

import s from './Loader.pcss';

interface ILoaderProps {
  className?: string;
  color?: string;
  size?: number | string;
}

const Loader: React.FC<ILoaderProps> = ({ className, color, size = '0.5' }) => {
  const transform = { transform: `scale(${size})` };

  return (
    <div className={cn(s.container, className)}>
      <div style={transform}>
        <div className={s.ballPulse}>
          <div style={{ backgroundColor: color }} />
          <div style={{ backgroundColor: color }} />
          <div style={{ backgroundColor: color }} />
        </div>
      </div>
    </div>
  );
};

Loader.defaultProps = {
  className: null,
  color: '#142d61',
  size: '0.5',
};

export default Loader;
