import React from 'react';
import Loader from './Loader';

const styles: React.CSSProperties = {
  position: 'absolute',
  background: '#fff',
  zIndex: 2,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const FullPage: React.FC = () => (
  <div style={styles}>
    <Loader color="#142d61" size={1} />
  </div>
);

export default FullPage;
