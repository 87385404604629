import * as TYPES from './constants';

interface IAdditionalQuestionsState {
  fetching: boolean;
  success: boolean;
  patching: boolean;
  distributions: any[];
  error: any;
}

const initialState = {
  fetching: false,
  success: false,
  patching: false,
  distributions: [],
  error: null,
};

interface IAdditionalQuestionsReducer {
  (state: IAdditionalQuestionsState, action): IAdditionalQuestionsState;
}

const additionalQuestionsReducer: IAdditionalQuestionsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH_ADDITIONAL_QUESTIONS:
      return { ...state, fetching: true };

    case TYPES.FETCH_ADDITIONAL_QUESTIONS_SUCCESS:
      return {
        ...initialState,
        fetching: false,
        success: payload.success,
        distributions: payload.distributions,
      };

    case TYPES.FETCH_ADDITIONAL_QUESTIONS_ERROR:
      return { ...state, fetching: false, ...payload };

    case TYPES.PATCH_ADDITIONAL_QUESTIONS:
      return { ...state, patching: true };

    case TYPES.PATCH_ADDITIONAL_QUESTIONS_SUCCESS:
      return { ...state, patching: false, success: payload.success };

    case TYPES.PATCH_ADDITIONAL_QUESTIONS_ERROR:
      // TODO: Eslint error should be fixed by refactoring (Denis Shvets)
      // eslint-disable-next-line no-param-reassign
      state.distributions.find(
        ({ id }) => payload.distributions[0].id === id
      ).additional_questions = payload.distributions[0].additional_questions;

      return { ...state, patching: false };

    case TYPES.CLEAR_ADDITIONAL_QUESTIONS:
      return initialState;

    case TYPES.SKIP_ADDITIONAL_QUESTIONS:
      return {
        ...state,
        distributions: state.distributions.map((distribution, key) => {
          if (payload === key) {
            // TODO: Eslint error should be fixed by refactoring (Denis Shvets)
            // eslint-disable-next-line no-param-reassign
            distribution.hide = true;
          }
          return distribution;
        }),
      };

    default:
      return state;
  }
};

export default additionalQuestionsReducer;
