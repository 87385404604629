import type { SW } from '@types';
import type { IHttpClient } from 'context/withHttpClient';

import * as Sentry from '@sentry/react';
import * as TYPES from './constants';
import { LOCAL_STORAGE_COMPLETENESS } from '../../../partials/widgets/profile/constants';
import { removeFromLocalStorage } from '../../../../utils/local_storage';
import { fetchCompletenessAction } from '../../../partials/widgets/profile/actions';

const setCurrentUser = (current_user) => ({
  type: TYPES.SET_CURRENT_USER,
  payload: current_user,
});

const clearSession = () => ({
  type: TYPES.CLEAR_SESSION,
});

interface IClearSessionAction {
  (): SW.Redux.ThunkAction;
}

export const clearSessionAction: IClearSessionAction = () => (dispatch) => {
  dispatch(clearSession());
  removeFromLocalStorage(LOCAL_STORAGE_COMPLETENESS);
};

const checkSession = () => ({
  type: TYPES.CHECK_SESSION,
});

export const checkSessionFinish = () => ({
  type: TYPES.CHECK_SESSION_FINISH,
});

interface ICheckSessionAction {
  (user, httpClient: IHttpClient): SW.Redux.ThunkAction;
}

export const authorizeUserAction = (user, httpClient) => (dispatch) => {
  dispatch(setCurrentUser(user));
  Sentry.configureScope((scope) => scope.setUser(user));
  dispatch(fetchCompletenessAction(httpClient));
};

interface ICheckSessionAction {
  (httpClient: IHttpClient): SW.Redux.ThunkAction<Promise<void>>;
}

export const checkSessionAction: ICheckSessionAction = (httpClient) => (
  dispatch
) => {
  dispatch(checkSession());
  return httpClient
    .get('user_session')
    .then((response) => {
      dispatch(checkSessionFinish());
      if (response.success) {
        dispatch(authorizeUserAction(response.current_user, httpClient));
      } else {
        dispatch(clearSessionAction());
      }
      return response;
    })
    .catch(() => {
      dispatch(checkSessionFinish());
      dispatch(clearSessionAction());
    });
};

const deleteSession = () => ({
  type: TYPES.DELETE_SESSION,
});

const deleteSessionFinish = () => ({
  type: TYPES.DELETE_SESSION_FINISH,
});

interface IDeleteSessionAction {
  (httpClient: IHttpClient): SW.Redux.ThunkAction<Promise<void>>;
}

export const deleteSessionAction: IDeleteSessionAction = (httpClient) => (
  dispatch
) => {
  dispatch(deleteSession());
  return httpClient.delete('user_session').then(() => {
    dispatch(deleteSessionFinish());
    dispatch(clearSessionAction());
  });
};
