import uniq from 'lodash/uniq';
import without from 'lodash/without';

import {
  ACCEPT,
  CLEAR,
  DECLINE,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  SUBMIT_FAILURE,
  SUBMIT_REQUEST,
  SUBMIT_SUCCESS,
} from './constants';

interface IOffersState {
  accepted: any[];
  declined: any[];
  error: any;
  isFetching: boolean;
  isPatching: boolean;
  isSuccessful: boolean;
  offers: any[];
}

const initialState: IOffersState = {
  accepted: [],
  declined: [],
  error: null,
  isFetching: false,
  isPatching: false,
  isSuccessful: false,
  offers: [],
};

interface IOffersReducer {
  (state: IOffersState, action): IOffersState;
}

const offersReducer: IOffersReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_SUCCESS:
      return {
        ...state,
        accepted: payload.accepted,
        isFetching: false,
        isSuccessful: payload.success,
        offers: payload.offers,
      };

    case FETCH_FAILURE:
      return {
        ...state,
        error: payload.error,
        isFetching: false,
      };

    case SUBMIT_REQUEST:
      return {
        ...state,
        isPatching: true,
      };

    case SUBMIT_SUCCESS:
      return {
        ...state,
        isPatching: false,
        isSuccessful: payload.success,
      };

    case SUBMIT_FAILURE:
      return {
        ...state,
        error: payload.error,
        isPatching: false,
      };

    case ACCEPT:
      return {
        ...state,
        accepted: uniq([...state.accepted, payload.id]),
        declined: without(state.declined, payload.id),
      };

    case DECLINE:
      return {
        ...state,
        accepted: without(state.accepted, payload.id),
        declined: uniq([...state.declined, payload.id]),
      };

    case CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default offersReducer;
