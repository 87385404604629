import { SW } from '@types';
import flatMap from 'lodash/flatMap';
import * as TYPES from './constants';

export interface IDelayedOffersState {
  fetched: boolean;
  failed: boolean;
  fetching: boolean;
  patching: boolean;
  acceptedIds: number[];
  declinedIds: number[];
  blocks: SW.Models.IOfferBlock[];
  offers: SW.Models.IOffer[];
}

const initialState: IDelayedOffersState = {
  fetched: false,
  failed: false,
  fetching: false,
  patching: false,
  acceptedIds: [],
  declinedIds: [],
  blocks: [],
  offers: [],
};

interface IDelayedOffersReducer {
  (state: IDelayedOffersState, action): IDelayedOffersState;
}

const delayedOffersReducer: IDelayedOffersReducer = (
  state = initialState,
  { type, payload }
): IDelayedOffersState => {
  switch (type) {
    case TYPES.FETCH:
      return {
        ...state,
        fetching: true,
      };

    case TYPES.FETCH_SUCCESS:
      return {
        ...initialState,
        fetched: true,
        fetching: false,
        failed: false,
        blocks: payload.distribution.blocks,
        offers: flatMap(payload.distribution.blocks, (block) => block.offers),
        acceptedIds: payload.distribution.accepted_ids,
        declinedIds: payload.distribution.declined_ids,
      };

    case TYPES.PATCH:
      return {
        ...state,
        patching: true,
        acceptedIds: payload.acceptedIds,
        declinedIds: payload.declinedIds,
      };

    case TYPES.PATCH_SUCCESS:
      return {
        ...state,
        patching: false,
        failed: false,
        blocks: payload.distribution.blocks,
        offers: flatMap(payload.distribution.blocks, (block) => block.offers),
        acceptedIds: payload.distribution.accepted_ids,
        declinedIds: payload.distribution.declined_ids,
      };

    case TYPES.REQUEST_ERROR:
      return {
        ...state,
        fetching: false,
        patching: false,
        failed: true,
        acceptedIds: payload?.acceptedIds || state.acceptedIds,
        declinedIds: payload?.declinedIds || state.declinedIds,
      };

    case TYPES.CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default delayedOffersReducer;
