import type { Breadcrumb, BreadcrumbHint } from '@sentry/types';
import captureEventByHttpCategory from './http';

interface ICaptureEventByType {
  (breadcrumb: Breadcrumb, hint: BreadcrumbHint): void;
}

const captureEventByType: ICaptureEventByType = (breadcrumb, hint) => {
  switch (breadcrumb.type) {
    case 'http': {
      captureEventByHttpCategory(breadcrumb, hint);
      break;
    }
    default:
  }
};

interface ICaptureEvent {
  (breadcrumb: Breadcrumb, hint: BreadcrumbHint): Breadcrumb;
}

const captureEvent: ICaptureEvent = (breadcrumb, hint) => {
  if (breadcrumb) {
    captureEventByType(breadcrumb, hint);
  }
  return breadcrumb;
};

export default captureEvent;
