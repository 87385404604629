import React from 'react';

interface IHoc {
  <T extends React.ComponentType<any>>(WrappedComponent: T): T;
}
interface IHocableSlim {
  (WrapperComponent: React.ComponentType): IHoc;
}

const hocableSlim: IHocableSlim = (WrapperComponent) => <
  T extends React.ComponentType<any>
>(
  WrappedComponent: T
): T => {
  const Component = (props) => (
    <WrapperComponent>
      <WrappedComponent {...props} />
    </WrapperComponent>
  );
  return Component as T;
};

export default hocableSlim;
