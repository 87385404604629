import React from 'react';

import * as Popup from '../../libs/Popup';
import Button from '../Button';

import styles from './Alert.pcss';

interface IAlertProps {
  title: string;
  subtitle?: string;
  button?: string;
  onClick(): any;
}

const Alert: React.FC<IAlertProps> = ({ title, subtitle, button, onClick }) => (
  <Popup.Container
    сlassName={styles.popup}
    name="alert"
    hasCloseButton={false}
    hasCloseOnClickOver={false}
  >
    <Popup.Body className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>

      <Button className={styles.button} theme="orange" onClick={onClick}>
        {button}
      </Button>
    </Popup.Body>
  </Popup.Container>
);

Alert.defaultProps = {
  subtitle: null,
  button: 'Ok',
};

export default Alert;
