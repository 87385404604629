export const POPUP_KEY = 'offers';
export const ACTION_KEY = 'offers';

export const FETCH_REQUEST = `${ACTION_KEY}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${ACTION_KEY}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${ACTION_KEY}/FETCH_FAILURE`;

export const SUBMIT_REQUEST = `${ACTION_KEY}/SUBMIT_REQUEST`;
export const SUBMIT_SUCCESS = `${ACTION_KEY}/SUBMIT_SUCCESS`;
export const SUBMIT_FAILURE = `${ACTION_KEY}/SUBMIT_FAILURE`;

export const ACCEPT = `${ACTION_KEY}/ACCEPT`;
export const CLEAR = `${ACTION_KEY}/CLEAR`;
export const DECLINE = `${ACTION_KEY}/DECLINE`;
