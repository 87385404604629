import * as TYPES from './constants';

// TODO[SL] should be splited by job_reference
interface ISimilarJobsState {
  isFetching: boolean;
  areJobsLoaded: boolean;
  jobs: any[];
  search: any;
}

const initialState: ISimilarJobsState = {
  isFetching: false,
  areJobsLoaded: false,
  jobs: [],
  search: {},
};

interface ISimilarJobsReducer {
  (state: ISimilarJobsState, action): ISimilarJobsState;
}

const similarJobsReducer: ISimilarJobsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH_SIMILAR_JOBS:
      return {
        ...state,
        isFetching: true,
      };

    case TYPES.FETCH_SIMILAR_JOBS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        areJobsLoaded: true,
        ...payload,
      };

    case TYPES.FETCH_SIMILAR_JOBS_ERROR:
      return {
        ...state,
        isFetching: false,
        ...payload,
      };

    default:
      return state;
  }
};

export default similarJobsReducer;
