import * as Sentry from '@sentry/react';
import type { Breadcrumb } from '@sentry/types';

interface IAddBreadcrumbToExtra {
  (breadcrumb: Breadcrumb): Breadcrumb;
}

const addToExtra: IAddBreadcrumbToExtra = (breadcrumb) => {
  if (breadcrumb) {
    const sortIndex = 10000000000 - breadcrumb.timestamp;
    const timeISO = new Date(breadcrumb.timestamp * 1000).toISOString();
    Sentry.setExtra(`breadcrumb-${sortIndex}-${timeISO}`, breadcrumb);
  }
  return breadcrumb;
};

export default addToExtra;
