import uniq from 'lodash/uniq';
import * as TYPES from './constants';

interface IVidiosState {
  fetching: boolean;
  groups: any[];
  rates: any;
}

const initial: IVidiosState = {
  fetching: false,
  groups: [],
  rates: null,
};

interface IVidiosReducer {
  (state: IVidiosState, action): IVidiosState;
}

const videosReducer: IVidiosReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case TYPES.FETCH_CAREER_VIDEOS:
      return { ...state, fetching: true };

    case TYPES.FETCH_CAREER_VIDEOS_SUCCESS: {
      const categories = uniq(payload.videos.map((video) => video.category));

      return {
        ...state,
        groups: categories.map((category) => ({
          title: category,
          videos: payload.videos.filter((video) => video.category === category),
        })),
        rates: payload.videos.reduce((rates, video) => {
          // TODO: Eslint error should be fixed by refactoring (Denis Shvets)
          // eslint-disable-next-line no-param-reassign
          rates[video.id] = video.rate;
          return rates;
        }, {}),
        fetching: false,
      };
    }

    case TYPES.FETCH_CAREER_VIDEOS_ERROR:
      return { ...state, fetching: false };

    case TYPES.SUBMIT_REVIEW:
      return { ...state, submittingReview: true };

    case TYPES.SUBMIT_REVIEW_SUCCESS:
      return { ...state, submittingReview: false };

    case TYPES.SUBMIT_REVIEW_ERROR:
      return { ...state, submittingReview: false };

    default:
      return state;
  }
};

export default videosReducer;
