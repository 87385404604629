import { createContext } from 'react';

export interface IAppMetadataContext {
  isChunksFetching: boolean;
  trackChunksFetching(action: Promise<any>): void;
  isSsrUsed: boolean;
  renderedBy: 'browser' | 'server';
  hepldeskRef: React.MutableRefObject<HTMLDivElement>;
}

const defaultAppMetadataContext: IAppMetadataContext = {
  isChunksFetching: false,
  trackChunksFetching: () => {
    throw Error(`not implemented`);
  },
  isSsrUsed: !process.env.BROWSER,
  renderedBy: process.env.BROWSER ? 'browser' : 'server',
  hepldeskRef: null,
};

const AppMetadataContext = createContext<IAppMetadataContext>(
  defaultAppMetadataContext
);

export default AppMetadataContext;
