import { combineReducers } from 'redux';

import * as TYPES from './constants';

interface IApplicationState {
  data: {
    notes: any[];
    reminders: any[];
  };
  error: any;
  isFetched: boolean;
  isFetching: boolean;
}

const initialState = {
  data: {
    notes: [],
    reminders: [],
  },
  error: null,
  isFetched: false,
  isFetching: false,
};

interface IApplicationReducer {
  (state: IApplicationState, action): IApplicationState;
}

const applicationReducer: IApplicationReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH_REQUEST:
      return {
        ...state,
        isFetched: false,
        isFetching: true,
      };

    case TYPES.FETCH_SUCCESS:
      return {
        ...state,
        data: payload.app,
        isFetched: true,
        isFetching: false,
      };

    case TYPES.FETCH_FAILURE:
      return {
        ...state,
        error: payload.error,
        isFetched: true,
        isFetching: false,
      };

    case TYPES.REMOVE_REQUEST:
    case TYPES.UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case TYPES.REMOVE_SUCCESS:
    case TYPES.REMOVE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case TYPES.UPDATE_SUCCESS:
      return {
        ...state,
        data: payload.app,
        isFetching: false,
      };

    case TYPES.UPDATE_FAILURE:
      return {
        ...state,
        error: payload.error,
        isFetching: false,
      };

    case TYPES.UPDATE_APP_AFTER_UPDATE_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          history: payload.app.history,
        },
      };

    case TYPES.UPDATE_APP_AFTER_CREATE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: [...state.data.notes, payload.note],
        },
      };

    case TYPES.UPDATE_APP_AFTER_REMOVE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.filter((v) => v.id !== payload),
        },
      };

    case TYPES.UPDATE_APP_AFTER_CREATE_REMINDER:
      return {
        ...state,
        data: {
          ...state.data,
          reminders: [...state.data.reminders, payload.reminder],
        },
      };

    case TYPES.UPDATE_APP_AFTER_REMOVE_REMINDER:
      return {
        ...state,
        data: {
          ...state.data,
          reminders: state.data.reminders.filter((v) => v.id !== payload),
        },
      };

    default:
      return state;
  }
};

export default combineReducers({ application: applicationReducer });
