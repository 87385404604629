import { SW } from '@types';
import * as TYPES from './constants';

export interface ISearchApiSubState {
  isFetched: boolean;
  isFetching: boolean;
  jobs: SW.Models.IJob[];
  page: number;
  total: number;
}

export interface ISearchApiState {
  [key: string]: ISearchApiSubState;
}

const initialState: ISearchApiState = {};
export const initialSubState: ISearchApiSubState = {
  isFetched: false,
  isFetching: false,
  jobs: [],
  page: 1,
  total: 0,
};

interface ISearchApiReducer {
  (state: ISearchApiState, action): ISearchApiState;
}

const searchApiReducer: ISearchApiReducer = (
  state = initialState,
  { type, payload }
): ISearchApiState => {
  switch (type) {
    case TYPES.FETCH_REQUEST:
      return {
        ...state,
        [payload.key as string]: {
          ...(state[payload.key] || initialSubState),
          isFetched: payload.page !== 1,
          isFetching: true,
        },
      };
    case TYPES.FETCH_SUCCESS:
      return {
        ...state,
        [payload.key as string]: {
          ...(state[payload.key] || initialSubState),
          isFetched: true,
          isFetching: false,
          jobs:
            payload.page === 1
              ? payload.jobs
              : [...state[payload.key].jobs, ...payload.jobs],
          total: payload.total_jobs_count || state[payload.key].total,
        },
      };
    case TYPES.FETCH_FAILURE:
      return {
        ...state,
        [payload.key as string]: {
          ...(state[payload.key] || initialSubState),
          isFetched: true,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

export default searchApiReducer;
