import React from 'react';

import WithResize from './WithResize';
import WithSession from './WithSession';

import { OnBoardingProvider } from './context/withOnBoarding';
import Routes from './Routes';
import * as WithSearch from './context/WithSearch';
import * as AppMetadata from './context/AppMetadata';
import { UrlProvider } from './context/withUrlMethods';
import * as LayerManager from './context/LayerManager';
import * as RouterRegistry from './context/RouterRegistry';
import { HttpClientProvider } from './context/withHttpClient';

const App: React.FC = () => (
  <AppMetadata.Provider>
    <RouterRegistry.Provider>
      <UrlProvider>
        <HttpClientProvider>
          <WithResize>
            <WithSession>
              <OnBoardingProvider>
                <WithSearch.Provider>
                  <LayerManager.Provider>
                    <Routes />
                  </LayerManager.Provider>
                </WithSearch.Provider>
              </OnBoardingProvider>
            </WithSession>
          </WithResize>
        </HttpClientProvider>
      </UrlProvider>
    </RouterRegistry.Provider>
  </AppMetadata.Provider>
);

export default App;
