import React from 'react';
import UserAgentContext from './Context';

interface IUserAgentProviderProps {
  userAgent: string;
}

const UserAgentProvider: React.FC<IUserAgentProviderProps> = (props) => {
  const { children, userAgent } = props;
  return (
    <UserAgentContext.Provider
      value={{
        userAgent,
      }}
    >
      {children}
    </UserAgentContext.Provider>
  );
};

export default UserAgentProvider;
