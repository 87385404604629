import { createContext } from 'react';

interface ILayerContext {
  ref?: React.RefObject<HTMLElement>;
  disable(): void;
  enable(): void;
}

const defaultLayerContext: ILayerContext = {
  ref: null,
  disable: () => {
    throw Error(`not implemented`);
  },
  enable: () => {
    throw Error(`not implemented`);
  },
};

const LayerContext = createContext<ILayerContext>(defaultLayerContext);

export default LayerContext;
