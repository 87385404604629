import * as TYPES from './constants';

interface ICurrentUser {
  first_name: string;
  last_name: string;
  email: string;
  is_pretending: boolean;
  only_remote_jobs: boolean;
}

interface ISessionState {
  current_user: ICurrentUser;
  checkingSession: boolean;
  deletingSession: boolean;
  isSessionChecked: boolean;
}

const initialState: ISessionState = {
  current_user: undefined,
  checkingSession: false,
  deletingSession: false,
  isSessionChecked: false,
};

interface ISessionReducer {
  (state: ISessionState, action): ISessionState;
}

const sessionReducer: ISessionReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.SET_CURRENT_USER:
      return {
        ...state,
        current_user: payload,
      };

    case TYPES.SET_CURRENT_USER_ONLY_REMOTE_JOBS:
      return {
        ...state,
        current_user: { ...state.current_user, only_remote_jobs: payload },
      };

    case TYPES.CLEAR_SESSION:
      return {
        ...initialState,
        isSessionChecked: state.isSessionChecked,
      };

    case TYPES.CHECK_SESSION:
      return { ...state, checkingSession: true };

    case TYPES.CHECK_SESSION_FINISH:
      return {
        ...state,
        checkingSession: false,
        isSessionChecked: true,
      };

    case TYPES.DELETE_SESSION:
      return { ...state, deletingSession: true };

    case TYPES.DELETE_SESSION_FINISH:
      return { ...state, deletingSession: false };

    default:
      return state;
  }
};

export default sessionReducer;
