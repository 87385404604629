export const ACTION_KEY = 'CompanyPage';

export const LOAD_COMPANY = `${ACTION_KEY}/LOAD_COMPANY`;
export const LOAD_COMPANY_SUCCESS = `${ACTION_KEY}/LOAD_COMPANY_SUCCESS`;
export const LOAD_COMPANY_ERROR = `${ACTION_KEY}/LOAD_COMPANY_ERROR`;

export const LOAD_RECENTLY_POSTED_JOBS = `${ACTION_KEY}/LOAD_RECENTLY_POSTED_JOBS`;
export const LOAD_RECENTLY_POSTED_JOBS_SUCCESS = `${ACTION_KEY}/LOAD_RECENTLY_POSTED_JOBS_SUCCESS`;
export const LOAD_RECENTLY_POSTED_JOBS_ERROR = `${ACTION_KEY}/LOAD_RECENTLY_POSTED_JOBS_ERROR`;

export const LOAD_INDUSTRY_COMPANIES = `${ACTION_KEY}/LOAD_INDUSTRY_COMPANIES`;
export const LOAD_INDUSTRY_COMPANIES_SUCCESS = `${ACTION_KEY}/LOAD_INDUSTRY_COMPANIES_SUCCESS`;
export const LOAD_INDUSTRY_COMPANIES_ERROR = `${ACTION_KEY}/LOAD_INDUSTRY_COMPANIES_ERROR`;
