import { SW } from '@types';
import * as TYPES from './constants';

export interface IExpressApplyJobsState {
  fetching: boolean;
  fetched: boolean;
  failed: boolean;
  jobs: SW.Models.IJob[];
}

const initialState: IExpressApplyJobsState = {
  fetching: false,
  fetched: false,
  failed: false,
  jobs: [],
};

interface IExpressApplyJobsReducer {
  (state: IExpressApplyJobsState, action): IExpressApplyJobsState;
}

const expressApplyJobsReducer: IExpressApplyJobsReducer = (
  state = initialState,
  { type, payload }
): IExpressApplyJobsState => {
  switch (type) {
    case TYPES.FETCH:
      return {
        ...state,
        fetching: true,
      };

    case TYPES.FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        failed: false,
        jobs: payload.jobs,
      };

    case TYPES.REQUEST_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };

    case TYPES.CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default expressApplyJobsReducer;
