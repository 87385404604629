import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './Footer.pcss';

interface IFooterProps {
  className?: string;
  isSticky?: boolean;
}

const Footer: React.FC<IFooterProps> = (props) => {
  const { children, className, isSticky } = props;
  const footerEl = useRef();
  const [isStucked, setStuck] = useState(false);

  useEffect(() => {
    if (footerEl.current && window.IntersectionObserver) {
      const callback = (entries) => {
        const [entry] = entries;
        setStuck(!entry.isIntersecting);
      };

      const observer = new window.IntersectionObserver(callback);
      observer.observe(footerEl.current);

      return observer.disconnect.bind(observer);
    }
    return () => {};
  }, [footerEl]);

  return (
    <>
      <div
        className={cn(styles.footer, className, {
          [styles.sticky]: isSticky,
          [styles.stucked]: isSticky && isStucked,
        })}
      >
        {children}
      </div>
      <div ref={footerEl} />
    </>
  );
};

Footer.defaultProps = {
  className: undefined,
  isSticky: false,
};

export default Footer;
