export const ACTION_KEY = 'videos';

// Fetch Videos
export const FETCH_CAREER_VIDEOS = `${ACTION_KEY}/FETCH_CAREER_VIDEOS`;
export const FETCH_CAREER_VIDEOS_SUCCESS = `${ACTION_KEY}/FETCH_CAREER_VIDEOS_SUCCESS`;
export const FETCH_CAREER_VIDEOS_ERROR = `${ACTION_KEY}/FETCH_CAREER_VIDEOS_ERROR`;

export const SUBMIT_REVIEW = `${ACTION_KEY}/SUBMIT_REVIEW`;
export const SUBMIT_REVIEW_SUCCESS = `${ACTION_KEY}/SUBMIT_REVIEW_SUCCESS`;
export const SUBMIT_REVIEW_ERROR = `${ACTION_KEY}/SUBMIT_REVIEW_ERROR`;
