import { createContext } from 'react';

interface ILayerManagerContext {
  generateId(): string;
  onAdd<T extends HTMLElement>(ref: React.RefObject<T>): void;
  onRemove<T extends HTMLElement>(ref: React.RefObject<T>): void;
}

const defaultLayerManagerContext: ILayerManagerContext = {
  generateId: () => {
    throw Error(`not implemented`);
  },
  onAdd: () => {
    throw Error(`not implemented`);
  },
  onRemove: () => {
    throw Error(`not implemented`);
  },
};

const LayerManagerContext = createContext<ILayerManagerContext>(
  defaultLayerManagerContext
);

export default LayerManagerContext;
