import Cookies from 'js-cookie';
import type { Event, EventHint } from '@sentry/types';
import type { Store } from 'redux';

const SORT_SYMBOL = ' ';

interface IEnhanceEvent {
  (event: Event, hint: EventHint, store: Store): Event;
}

const enhance: IEnhanceEvent = (event, hint, store) => {
  if (event) {
    return {
      ...event,
      extra: {
        ...event.extra,
        [`${SORT_SYMBOL}redux.state`]: store.getState(),
        [`${SORT_SYMBOL}cookies`]: Cookies.getJSON(),
      },
    };
  }
  return event;
};

export default enhance;
