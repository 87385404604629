import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import type { Store } from 'redux';
import type { History } from 'history';
import createRootReducer from '../reducers';

import gtmMiddleware from './middlewares/gtm';

import createStore from './createStore';

const IS_DEVELOPMENT = process.env.ENV === 'development';
const IS_PRODUCTION = process.env.ENV === 'production';

const createClientStoreAndBrowserHistory = (): {
  store: Store;
  browserHistory: History;
} => {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();
  const extensions = [sentryReduxEnhancer];

  if (!IS_PRODUCTION) {
    const { __REDUX_DEVTOOLS_EXTENSION__ } = window;
    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
      extensions.push(__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const browserHistory = createBrowserHistory();

  const middlewares = [gtmMiddleware];

  const store = createStore(
    window.REDUX_STATE,
    browserHistory,
    middlewares,
    extensions
  );

  if (module.hot && IS_DEVELOPMENT) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(createRootReducer(browserHistory));
    });
  }

  if (process.env.ENV !== 'local') {
    delete window.REDUX_STATE;
  }

  return { store, browserHistory };
};

export default createClientStoreAndBrowserHistory;
