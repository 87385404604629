import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
} from 'react-redux';
import { bindActionCreators } from 'redux';
import type { InferThunkActionCreatorType } from 'react-redux';
import type { ActionCreator } from 'redux';
import type { SW } from '@types';

export { default as createClientStoreAndBrowserHistory } from './createClientStoreAndBrowserHistory';
export { default as createServerStore } from './createServerStore';

export const useSelector = <TSelected = unknown>(
  selector: (state: SW.Redux.RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected =>
  useReduxSelector<SW.Redux.RootState, TSelected>(selector, equalityFn);

export const useDispatch = (): SW.Redux.ThunkDispatch =>
  useReduxDispatch<SW.Redux.ThunkDispatch>();

export const useBindDispatch = () => {
  const dispatch = useDispatch();
  return <TActionCreator extends ActionCreator<any>>(action: TActionCreator) =>
    bindActionCreators<
      TActionCreator,
      InferThunkActionCreatorType<TActionCreator>
    >(action, dispatch);
};
