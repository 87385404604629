import React from 'react';

import useNotifications from './useNotifications';
import Notice from './Notice';

import styles from './FlashNotifications.pcss';

const FlashNotifications: React.FC = () => {
  const { notifications, hideNotification } = useNotifications();

  return (
    <div className={styles.container}>
      {notifications.map((notice) => (
        <Notice
          key={notice.uuid}
          content={notice.message}
          onClose={() => {
            hideNotification(notice);
          }}
          type={notice.type}
        />
      ))}
    </div>
  );
};

export default FlashNotifications;
