import type { SW } from '@types';
import { ADD_PIXEL } from './constants';

// Add Pixel
export const addPixel = (params) => ({ type: ADD_PIXEL, payload: params });

interface IPixelAction {
  (params): SW.Redux.ThunkAction;
}

export const pixelAction: IPixelAction = (params) => (dispatch) => {
  dispatch(addPixel(params));
};
