import React from 'react';
import cn from 'classnames';
import { MdClose } from 'react-icons/md';

import Layer from '../../layouts/Base/Layer';
import LoaderComponent from '../../partials/LoaderComponent';
import Modal from './Modal';
import Overlay from './Overlay';
import { useUrlMethods } from '../../../context/withUrlMethods';
import { doNothing } from '../../../constants';

import styles from './Popup.pcss';

interface IPopupProps {
  name: string;
  hasCloseButton?: boolean;
  hasCloseOnClickOver?: boolean;
  isLoading?: boolean;
  сlassName?: string;
}

const Popup: React.FC<IPopupProps> = (props) => {
  const {
    children,
    name,
    hasCloseButton,
    hasCloseOnClickOver,
    isLoading,
    сlassName,
  } = props;

  const { closePopup } = useUrlMethods();

  const handleClose = () => {
    closePopup(name);
  };

  return (
    <Layer>
      <Overlay className={styles.overlay}>
        <div
          className={styles.gap}
          onClick={hasCloseOnClickOver ? handleClose : doNothing}
          onKeyDown={doNothing}
          role="button"
          tabIndex={-1}
        />
        <div className={styles.container}>
          <Modal name={name} className={cn(styles.modal, сlassName)}>
            {children}
            {hasCloseButton && (
              <MdClose
                data-cy={`${name}-modal-close`}
                className={cn(styles.close)}
                onClick={handleClose}
                onKeyPress={(e) => (e.charCode === 13 ? handleClose() : null)}
              />
            )}
            {isLoading && (
              <div className={styles.spinner}>
                <LoaderComponent indicator />
              </div>
            )}
          </Modal>
          <div
            className={styles.middle}
            onClick={hasCloseOnClickOver ? handleClose : doNothing}
            onKeyDown={doNothing}
            role="button"
            tabIndex={-1}
          />
        </div>
        <div
          className={styles.gap}
          onClick={hasCloseOnClickOver ? handleClose : doNothing}
          onKeyDown={doNothing}
          role="button"
          tabIndex={-1}
        />
      </Overlay>
    </Layer>
  );
};

Popup.defaultProps = {
  hasCloseButton: true,
  hasCloseOnClickOver: false,
  isLoading: false,
  сlassName: undefined,
};

export default Popup;
