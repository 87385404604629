import * as TYPES from './constants';

interface IAlertState {
  fetching: boolean;
  alert: any;
  errors: any;
}

const initialState: IAlertState = {
  fetching: false,
  alert: {},
  errors: null,
};

interface IAlertReducer {
  (state: IAlertState, action): IAlertState;
}

const alertReducer: IAlertReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH_ALERT:
      return { ...state, fetching: true };

    case TYPES.FETCH_ALERT_SUCCESS:
      return { ...state, fetching: false, alert: payload.alert, ...payload };

    case TYPES.FETCH_ALERT_ERROR:
      return { ...state, fetching: false, ...payload };

    default:
      return state;
  }
};

export default alertReducer;
