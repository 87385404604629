import React from 'react';
import { useUrlMethods } from '../../../../context/withUrlMethods';
import type { ILazyPopupRouteProps } from '../ILazyRouteProps';

const PopupRoute: React.FC<ILazyPopupRouteProps> = ({
  component: Component,
  getParams,
}) => {
  const { getQueryParams } = useUrlMethods();
  return <Component {...getParams(getQueryParams())} />;
};

PopupRoute.defaultProps = {
  getParams: () => ({}),
};

export default PopupRoute;
