import React, { useEffect } from 'react';
import { Route as OriginalRoute } from 'react-router-dom';
import type { RouteProps, match } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { scrollToTop } from '../../../utils/scroll_to';

// @ts-ignore
const SentryRoute = Sentry.withSentryRouting(OriginalRoute);

export interface IRouteProps extends RouteProps {
  path: string;
  getParams?(match: match<any>): object;
  isTab?: boolean;
}

const Route: React.FC<IRouteProps> = ({
  path,
  component: Component,
  render,
  getParams,
  isTab,
  ...rest
}) => {
  useEffect(() => {
    if (!isTab) {
      scrollToTop();
    }
  }, [path]);

  return (
    <SentryRoute
      path={path}
      {...rest}
      render={(routeProps) => {
        const componentProps = {
          ...routeProps,
          ...getParams(routeProps.match),
        };
        return render ? (
          render(componentProps)
        ) : (
          <Component {...componentProps} />
        );
      }}
    />
  );
};

Route.defaultProps = {
  getParams: () => ({}),
  isTab: false,
};

export default Route;
