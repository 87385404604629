import * as TYPES from './constants';

interface IOfferState {
  offer: any;
  resume: any;
  isFetching: boolean;
  isSubmiting: boolean;
  success: boolean;
  errors: any;
}

const initialState: IOfferState = {
  offer: null,
  resume: null,
  isFetching: false,
  isSubmiting: false,
  success: false,
  errors: null,
};

const Actions = {};

Actions[TYPES.FETCH_OFFER_PAGE] = (state) => ({
  ...state,
  isFetching: true,
});

Actions[TYPES.FETCH_OFFER_PAGE_SUCCESS] = (
  state,
  {
    payload: {
      success,
      offer_page: { offer, resume },
    },
  }
) => ({
  ...state,
  offer,
  success,
  resume: state.resume || resume,
  isFetching: false,
});

Actions[TYPES.FETCH_OFFER_PAGE_ERROR] = (
  state,
  { payload: { success, errors } }
) => ({
  ...state,
  success,
  errors: errors || {},
  isFetching: false,
});

Actions[TYPES.APPLY_OFFER_PAGE] = (state) => ({
  ...state,
  isSubmiting: true,
});

Actions[TYPES.APPLY_OFFER_PAGE_SUCCESS] = (
  state,
  { payload: { success } }
) => ({
  ...state,
  success,
  isSubmiting: false,
});

Actions[TYPES.APPLY_OFFER_PAGE_ERROR] = (
  state,
  { payload: { success, errors } }
) => ({
  ...state,
  success,
  errors: errors || {},
  isSubmiting: false,
});

Actions[TYPES.APPLY_RESUME] = (state, { payload: { resume } }) => ({
  ...state,
  resume,
  errors: {
    ...state.errors,
    resume: null,
  },
});

interface IOfferReducer {
  (state: IOfferState, action): IOfferState;
}

const offerReducer: IOfferReducer = (state = initialState, action) => {
  const handler = Actions[action.type];
  return (handler && handler(state, action)) || state;
};

export default offerReducer;
