import 'isomorphic-fetch';
import { buildQueryByParams } from '../../withUrlMethods';
import responseMiddleware from './responseMiddleware';
import responseToJson from './responseToJson';

const httpRequest = (
  dispatch,
  changeLocation,
  cookieManager,
  customUserAgent,
  method,
  action,
  params,
  body,
  headers
) => {
  const options: RequestInit = {
    method,
    headers: {
      ...(cookieManager
        ? { Cookie: cookieManager.fetchRequestCookieHeader() }
        : {}),
      ...(customUserAgent ? { 'user-agent': customUserAgent } : {}),
      ...headers,
    },
    credentials: 'same-origin',
    ...(body ? { body } : undefined),
  };

  const url = `${
    process.env.API_HOST || process.env.HOST
  }/client/api/${action}${buildQueryByParams(params)}`;

  return fetch(url, options).then((response) =>
    responseToJson(cookieManager, response)
      .then((responseJson) => {
        responseMiddleware(dispatch, changeLocation, response, responseJson);
        return responseJson;
      })
      .catch((errorJson) => {
        responseMiddleware(dispatch, changeLocation, response, errorJson);
        throw errorJson;
      })
  );
};

export default httpRequest;
