import React from 'react';

import * as Popup from '../../libs/Popup';
import Button from '../Button';

import styles from './Confirmation.pcss';

interface IConfirmationProps {
  title: string;
  subtitle?: any;
  acceptButton: string;
  declineButton: string;
  onAccept(): any;
  onDecline(): any;
}

const Confirmation: React.FC<IConfirmationProps> = ({
  title,
  subtitle,
  acceptButton,
  declineButton,
  onAccept,
  onDecline,
}) => (
  <Popup.Container
    сlassName={styles.popup}
    name="сonfirmation"
    hasCloseButton={false}
    hasCloseOnClickOver={false}
  >
    <Popup.Body className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
      <div className={styles.buttonGroup}>
        <Button className={styles.button} theme="orange" onClick={onAccept}>
          {acceptButton}
        </Button>
        <Button
          className={styles.button}
          theme="transparent"
          onClick={onDecline}
        >
          {declineButton}
        </Button>
      </div>
    </Popup.Body>
  </Popup.Container>
);

Confirmation.defaultProps = {
  subtitle: null,
  acceptButton: 'Ok',
  declineButton: 'Cancel',
};

export default Confirmation;
