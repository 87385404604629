import { createContext } from 'react';

export interface IOnBoardingContext {
  startOnBoarding(): void;
  stopOnBoarding(): void;
  isOnBoardingActive: boolean;
}

const defaultOnBoardingContext: IOnBoardingContext = {
  startOnBoarding: () => {
    throw Error(`not implemented`);
  },
  stopOnBoarding: () => {
    throw Error(`not implemented`);
  },
  isOnBoardingActive: false,
};

const OnBoardingContext = createContext<IOnBoardingContext>(
  defaultOnBoardingContext
);

export default OnBoardingContext;
