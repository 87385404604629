import React from 'react';
import { debounce, throttle } from 'throttle-debounce';

import ResizeContext from './context/ResizeContext';

interface IWithResizeState {
  isResizing: boolean;
  isResizingHorizontally: boolean;
  isResizingVertically: boolean;
  heightsAreInitialized?: boolean;
  windowInnerWidth?: number;
  windowInnerHeight?: number;
}

class WithResize extends React.PureComponent<{}, IWithResizeState> {
  state = {
    isResizing: false,
    isResizingHorizontally: false,
    isResizingVertically: false,
    heightsAreInitialized: null,
    windowInnerWidth: null,
    windowInnerHeight: null,
  };

  onResize = throttle(300, () => {
    this.setState((state) => ({
      isResizingHorizontally: state.windowInnerWidth !== window.innerWidth,
      isResizingVertically: state.windowInnerHeight !== window.innerHeight,
      windowInnerHeight: window.innerHeight,
      windowInnerWidth: window.innerWidth,
    }));
  });

  onResizeStart = debounce(300, true, () => {
    this.setState({ isResizing: true });
  });

  onResizeFinish = debounce(300, () => {
    this.setState({
      isResizing: false,
      isResizingHorizontally: false,
      isResizingVertically: false,
    });
  });

  componentDidMount() {
    this.setState({
      windowInnerHeight: window.innerHeight,
      windowInnerWidth: window.innerWidth,
      heightsAreInitialized: true,
    });
    window.addEventListener('resize', this.onResize);
    window.addEventListener('resize', this.onResizeStart);
    window.addEventListener('resize', this.onResizeFinish);
  }

  componentWillUnmount() {
    if (this.state.heightsAreInitialized) {
      window.removeEventListener('resize', this.onResize);
      window.removeEventListener('resize', this.onResizeStart);
      window.removeEventListener('resize', this.onResizeFinish);
    }
    this.onResizeStart.cancel();
    this.onResizeFinish.cancel();
  }

  render() {
    const { children } = this.props;
    const {
      isResizing,
      isResizingHorizontally,
      isResizingVertically,
      windowInnerHeight,
      windowInnerWidth,
    } = this.state;
    return (
      <ResizeContext.Provider
        value={{
          isResizing,
          isResizingHorizontally,
          isResizingVertically,
          windowInnerHeight,
          windowInnerWidth,
        }}
      >
        {children}
      </ResizeContext.Provider>
    );
  }
}

export default WithResize;
