import { useCallback, useState } from 'react';
import without from 'lodash/without';
import type { SW } from '@types';

import { useSelector, useDispatch } from '../../../../store';
import useWeakMap from '../../../../common/hooks/useWeakMap';
import useArrDiffEffect from '../../../../common/hooks/useArrDiffEffect';
import { hideNotificationAction } from './redux/actions';

const SHOW_DELAY = 300;

function useNotifications(): {
  notifications: SW.Models.IFlashNotification[];
  hideNotification(notice: SW.Models.IFlashNotification): void;
} {
  const [, setlastNoticeTime] = useState(Date.now());
  const dispatch = useDispatch();
  const {
    get: getTimeoutsId,
    set: setTimeoutsId,
    remove: removeTimeoutsId,
  } = useWeakMap<object, number>(new WeakMap());
  const [shownNotifications, setShownNotifications] = useState<
    SW.Models.IFlashNotification[]
  >([]);
  const notifications = useSelector((store) => store.widgets.notifications);

  const hideNotification = useCallback((notice) => {
    const timeoutId = getTimeoutsId(notice);
    if (removeTimeoutsId(notice)) {
      window.clearTimeout(timeoutId);
    }
    dispatch(hideNotificationAction(notice));
  }, []);

  const showNotification = useCallback((notice) => {
    const timeoutId = window.setTimeout(() => {
      hideNotification(notice);
    }, notice.duration);
    setTimeoutsId(notice, timeoutId);
    setShownNotifications((prevShownNotifications) => [
      ...prevShownNotifications,
      notice,
    ]);
  }, []);

  const enqueueNotification = useCallback((notice) => {
    setlastNoticeTime((prevNoticeTime) => {
      const now = Date.now();
      const showTime =
        (now > prevNoticeTime ? now : prevNoticeTime) + SHOW_DELAY;
      window.setTimeout(() => {
        showNotification(notice);
      }, showTime - now);
      return showTime;
    });
  }, []);

  useArrDiffEffect(({ added, removed }) => {
    added.forEach((notice) => {
      enqueueNotification(notice);
    });
    setShownNotifications((prevShownNotifications) =>
      without(prevShownNotifications, ...removed)
    );
  }, notifications);

  return { notifications: shownNotifications, hideNotification };
}

export default useNotifications;
