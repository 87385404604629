import { useSelector } from '../../../store';

interface IUseJobRecommendationsPagePath {
  (): string;
}

const useJobRecommendationsPagePath: IUseJobRecommendationsPagePath = () => {
  const onlyRemoteJobs = useSelector(
    (state) =>
      state.auth.session.current_user &&
      state.auth.session.current_user.only_remote_jobs
  );
  return `/job/recommendations${onlyRemoteJobs ? '?only_remote_jobs=' : ''}`;
};

export default useJobRecommendationsPagePath;
