import * as TYPES from './constants';

interface IAtsWidgetState {
  fetching: boolean;
  ats: any[];
  error: any;
}

const widgetState: IAtsWidgetState = {
  fetching: false,
  ats: [],
  error: null,
};
interface IAtsWidgetReducer {
  (state: IAtsWidgetState, action): IAtsWidgetState;
}

const atsWidgetReducer: IAtsWidgetReducer = (
  state = widgetState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH_ATS_WIDGET:
      return { ...state, fetching: true };

    case TYPES.FETCH_ATS_WIDGET_SUCCESS:
      return {
        ...state,
        fetching: false,
        success: payload.success,
        ats: payload.ats_setting.ats_credentials,
      };

    case TYPES.FETCH_ATS_WIDGET_ERROR:
      return { ...state, fetching: false, ...payload };

    default:
      return state;
  }
};

export default atsWidgetReducer;
