import React from 'react';

export interface ISearchContext {
  query?: string;
  setQuery: (query: string) => void;
  location?: string;
  setLocation: (location: object) => void;
  total?: number;
  setTotal: (total: number) => void;
  isFetching: boolean;
  setFetching: (val: boolean) => void;
}

const INITIAL_VALUE: ISearchContext = {
  setQuery: () => {},
  setLocation: () => {},
  setTotal: () => {},
  isFetching: false,
  setFetching: () => {},
};

const SearchContext = React.createContext<ISearchContext>(INITIAL_VALUE);

export default SearchContext;
