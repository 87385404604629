import { combineReducers } from 'redux';

import { ACTION_KEY as PHONE_COMMUNICATION_ACTION_KEY } from './PhoneCommunication/usePhoneCommunication/constants';
import phoneCommunicationReducer from './PhoneCommunication/usePhoneCommunication/reducer';
import { ACTION_KEY as EMAIL_COMMUNICATION_ACTION_KEY } from './EmailCommunication/useEmailCommunication/constants';
import emailCommunicationReducer from './EmailCommunication/useEmailCommunication/reducer';

export default combineReducers({
  [PHONE_COMMUNICATION_ACTION_KEY]: phoneCommunicationReducer,
  [EMAIL_COMMUNICATION_ACTION_KEY]: emailCommunicationReducer,
});
