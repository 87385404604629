import { ACTION_KEY as PROFILE_KEY } from '../../constants';

export const ACTION_KEY = 'your-ideal-job';

export const FETCH_REQUEST = `${PROFILE_KEY}/${ACTION_KEY}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${PROFILE_KEY}/${ACTION_KEY}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${PROFILE_KEY}/${ACTION_KEY}/FETCH_FAILURE`;

export const ADD_TAG_REQUEST = `${PROFILE_KEY}/${ACTION_KEY}/ADD_TAG_REQUEST`;
export const ADD_TAG_SUCCESS = `${PROFILE_KEY}/${ACTION_KEY}/ADD_TAG_SUCCESS`;
export const ADD_TAG_FAILURE = `${PROFILE_KEY}/${ACTION_KEY}/ADD_TAG_FAILURE`;

export const REMOVE_HISTORY_TAG_REQUEST = `${PROFILE_KEY}/${ACTION_KEY}/REMOVE_HISTORY_TAG_REQUEST`;
export const REMOVE_HISTORY_TAG_SUCCESS = `${PROFILE_KEY}/${ACTION_KEY}/REMOVE_HISTORY_TAG_SUCCESS`;
export const REMOVE_HISTORY_TAG_FAILURE = `${PROFILE_KEY}/${ACTION_KEY}/REMOVE_HISTORY_TAG_FAILURE`;

export const REMOVE_TAG_REQUEST = `${PROFILE_KEY}/${ACTION_KEY}/REMOVE_TAG_REQUEST`;
export const REMOVE_TAG_SUCCESS = `${PROFILE_KEY}/${ACTION_KEY}/REMOVE_TAG_SUCCESS`;
export const REMOVE_TAG_FAILURE = `${PROFILE_KEY}/${ACTION_KEY}/REMOVE_TAG_FAILURE`;

export const SET_FUNC_SPEC_REQUEST = `${PROFILE_KEY}/${ACTION_KEY}/SET_FUNC_SPEC_REQUEST`;
export const SET_FUNC_SPEC_SUCCESS = `${PROFILE_KEY}/${ACTION_KEY}/SET_FUNC_SPEC_SUCCESS`;
export const SET_FUNC_SPEC_FAILURE = `${PROFILE_KEY}/${ACTION_KEY}/SET_FUNC_SPEC_FAILURE`;

export const CHANGE_ONLY_REMOTE_JOBS_REQUEST = `${PROFILE_KEY}/${ACTION_KEY}/CHANGE_ONLY_REMOTE_JOBS_REQUEST`;
export const CHANGE_ONLY_REMOTE_JOBS_SUCCESS = `${PROFILE_KEY}/${ACTION_KEY}/CHANGE_ONLY_REMOTE_JOBS_SUCCESS`;
export const CHANGE_ONLY_REMOTE_JOBS_FAILURE = `${PROFILE_KEY}/${ACTION_KEY}/CHANGE_ONLY_REMOTE_JOBS_FAILURE`;
