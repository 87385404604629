import { push } from 'connected-react-router';
import { stringify, parse } from 'qs';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import type { Dispatch } from 'redux';
import type { History, Location } from 'history';
import type { IStringifyOptions } from 'qs';
import type { IRouterRegistry } from '../RouterRegistry';
import type { IUrlProviderContext } from './UrlProviderContext';

const STRINGIFY_ATTRIBUTES: IStringifyOptions = {
  arrayFormat: 'brackets',
  encodeValuesOnly: true,
};

export const buildQueryByParams = (params) =>
  isEmpty(params) ? '' : `?${stringify(params, STRINGIFY_ATTRIBUTES)}`;

class UrlManager implements IUrlProviderContext {
  get history() {
    return this.historyRef.current;
  }

  get location() {
    return this.locationRef.current;
  }

  private get dispatch() {
    return this.dispatchRef.current;
  }

  private get routerRegistry() {
    return this.routerRegistryRef.current;
  }

  constructor(
    private historyRef: React.MutableRefObject<History>,
    private locationRef: React.MutableRefObject<Location>,
    private dispatchRef: React.MutableRefObject<Dispatch>,
    private routerRegistryRef: React.MutableRefObject<IRouterRegistry>
  ) {}

  buildClientPath = (path, params = {}) =>
    `${path}${buildQueryByParams(params)}`;

  changeLocation = async (location, extendCurrent?: false) => {
    await this.routerRegistry.load(location);
    if (extendCurrent) {
      this.dispatch(push({ ...this.location, ...location }));
    } else {
      this.dispatch(push(location));
    }
  };

  getQueryParams = () =>
    parse(this.location.search, { ignoreQueryPrefix: true });

  getQueryKeys = () => {
    const keys = Object.keys(this.getQueryParams());
    const { search } = this.location;
    return keys.sort((key1, key2) => {
      return (
        (search.match(`(/?|&)${key1}((\\[\\])?=|$)`)?.index || -1) -
        (search.match(`(/?|&)${key2}((\\[\\])?=|$)`)?.index || -1)
      );
    });
  };

  private buildModifiedLocation = (
    locationQueryModifier,
    params = undefined
  ) => {
    const locationQuery = this.getQueryParams();
    const search = `?${stringify(
      locationQueryModifier(locationQuery, params),
      STRINGIFY_ATTRIBUTES
    )}`;

    return {
      pathname: this.location.pathname,
      search,
    };
  };

  changeQueryParams = (params) => {
    const normalizedParams = mapValues(params, (v) =>
      v === undefined ? '' : v
    );

    this.changeLocation(
      this.buildModifiedLocation((locationQuery) => ({
        ...locationQuery,
        ...normalizedParams,
      }))
    );
  };

  removeQueryParam = (key) => {
    this.changeLocation(
      this.buildModifiedLocation((locationQuery) => {
        // eslint-disable-next-line no-param-reassign
        delete locationQuery[key];
        return locationQuery;
      })
    );
  };

  pushArrayQueryParam = (key, value) => {
    this.changeLocation(
      this.buildModifiedLocation((locationQuery) => {
        const currVal = locationQuery[key] || [];
        currVal.push(value);
        return { ...locationQuery, [key]: currVal };
      })
    );
  };

  removeArrayQueryParam = (key, index) => {
    this.changeLocation(
      this.buildModifiedLocation((locationQuery) => {
        const currVal = locationQuery[key] || [];
        if (typeof index === 'number') {
          currVal.splice(index, 1);
        } else {
          const i = currVal.indexOf(index);
          currVal.splice(i, 1);
        }

        return { ...locationQuery, [key]: currVal };
      })
    );
  };

  isPage = (page) => this.location.pathname.indexOf(page.split('?')[0]) > -1;

  buildPopupLocation = (
    openPopupName,
    openPopupValue = null,
    closePopupName = null,
    additionalQueryParams = {}
  ) => {
    const queryParams = {
      ...(openPopupName ? { [openPopupName]: openPopupValue } : {}),
      ...additionalQueryParams,
    };

    return this.buildModifiedLocation((locationQuery, innerUrlParams) => {
      if (closePopupName) {
        // TODO: Eslint error should be fixed by refactoring (Denis Shvets)
        // eslint-disable-next-line no-param-reassign
        delete locationQuery[closePopupName];
      }
      return { ...locationQuery, ...innerUrlParams };
    }, queryParams);
  };

  openPopup = (
    openPopupName,
    openPopupValue = null,
    closePopupName = null,
    additionalQueryParams = {}
  ) =>
    this.changeLocation(
      this.buildPopupLocation(
        openPopupName,
        openPopupValue,
        closePopupName,
        additionalQueryParams
      )
    );

  closePopup = (closePopupName) =>
    this.changeLocation(this.buildPopupLocation(null, null, closePopupName));
}

export default UrlManager;
