// TODO[SL]: use js Array.map
import map from 'lodash/map';
import isPlainObject from 'lodash/isPlainObject';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

const mapKeysDeep = (obj, fn) =>
  (isArray(obj) && map(obj, (innerObj) => mapKeysDeep(innerObj, fn))) ||
  (isPlainObject(obj) &&
    mapValues(mapKeys(obj, fn), (value) => mapKeysDeep(value, fn))) ||
  obj;

export const camelizeKeysDeep = <T extends Record<string, any>>(obj: T | T[]) =>
  mapKeysDeep(obj, (val, key) => camelCase(key));

export const snakeizeKeysDeep = <T extends Record<string, any>>(obj: T | T[]) =>
  mapKeysDeep(obj, (val, key) => snakeCase(key));
