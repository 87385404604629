import React, { useEffect } from 'react';
import { useUrlMethods } from '../../../../context/withUrlMethods';
import { ILazyPopupRouteProps } from '../ILazyRouteProps';
import { useRouterRegistry } from '../../../../context/RouterRegistry';

interface IPopupSwitchProps {
  children: React.ReactElement<ILazyPopupRouteProps>[];
}

const PopupSwitch: React.FC<IPopupSwitchProps> = (props) => {
  const { children } = props;
  const { getQueryParams, getQueryKeys } = useUrlMethods();
  const routerRegistry = useRouterRegistry();

  const queryParams = getQueryParams();
  const keys = getQueryKeys();

  useEffect(() => {
    React.Children.forEach(children, (child) => {
      const { path } = child.props;
      routerRegistry.registerPopup(path, child.props);
    });
  }, []);

  const matched = [];

  React.Children.forEach(children, (child) => {
    const { path } = child.props;
    if (path in queryParams) {
      matched.push(child);
    }
  });

  return (
    <>
      {matched.sort(
        (ch1, ch2) =>
          keys.indexOf(ch1.props.path) - keys.indexOf(ch2.props.path)
      )}
    </>
  );
};

export default PopupSwitch;
