import { ACTION_KEY as JOBS_UA_KEY } from '../../constants';

export const POPUP_KEY = 'action';
export const ACTION_KEY = 'Action';

export const FORM_NAME = `${JOBS_UA_KEY}_${ACTION_KEY}`;

export const RESET_ACTION = `${ACTION_KEY}/RESET_ACTION`;
// Fetch Action
export const FETCH_ACTION = `${ACTION_KEY}/FETCH_ACTION`;
export const FETCH_ACTION_SUCCESS = `${ACTION_KEY}/FETCH_ACTION_SUCCESS`;
export const FETCH_ACTION_ERROR = `${ACTION_KEY}/FETCH_ACTION_ERROR`;

// Post Action
export const PATCH_ACTION = `${ACTION_KEY}/PATCH_ACTION`;
export const PATCH_ACTION_SUCCESS = `${ACTION_KEY}/PATCH_ACTION_SUCCESS`;
export const PATCH_ACTION_ERROR = `${ACTION_KEY}/PATCH_ACTION_ERROR`;

export const SHOW_POPUP = `${ACTION_KEY}/SHOW_POPUP`;
