export { default as Route } from './Route';
export { default as PrivateRoute } from './PrivateRoute';
export { default as PublicRoute } from './PublicRoute';
export { default as PopupRoute } from './PopupRoute';

export { default as Link, HashLink, ExtLink } from './Link';
export { default as PopupLink } from './PopupLink';

export { Switch } from 'react-router-dom';
export { default as PageSwitch } from './PageSwitch';
export { default as PopupSwitch } from './PopupSwitch';

export { Redirect } from 'react-router-dom';

export type { RouteComponentProps } from 'react-router-dom';
export type {
  ILazyPageRouteProps,
  ILazyPopupRouteProps,
} from './ILazyRouteProps';
