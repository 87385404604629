import type { SW } from '@types';
import type { IHttpClient } from 'context/withHttpClient';

import * as TYPES from './constants';
import { fetchAlertAction } from '../Alert/actions';

// Fetch Widget Offers
const fetchWidgetOffers = () => ({
  type: TYPES.FETCH_WIDGET_OFFERS,
});
const fetchWidgetOffersSuccess = (params) => ({
  type: TYPES.FETCH_WIDGET_OFFERS_SUCCESS,
  payload: params,
});
const fetchWidgetOffersError = (params) => ({
  type: TYPES.FETCH_WIDGET_OFFERS_ERROR,
  payload: params,
});

interface IFetchWidgetOffersAction {
  (httpClient: IHttpClient): SW.Redux.ThunkAction<Promise<void>>;
}

export const fetchWidgetOffersAction: IFetchWidgetOffersAction = (
  httpClient
) => (dispatch) => {
  dispatch(fetchWidgetOffers());

  return httpClient.get('widgets/offers').then((response) => {
    if (response.success) {
      dispatch(fetchWidgetOffersSuccess(response));
    } else {
      dispatch(fetchWidgetOffersError(response));
    }
    return response;
  });
};

// Patch Widget Offers
const patchWidgetOffers = (params) => ({
  type: TYPES.PATCH_WIDGET_OFFERS,
  payload: params,
});
const patchWidgetOffersSuccess = (params) => ({
  type: TYPES.PATCH_WIDGET_OFFERS_SUCCESS,
  payload: params,
});
const patchWidgetOffersError = (params) => ({
  type: TYPES.PATCH_WIDGET_OFFERS_ERROR,
  payload: params,
});

interface IPatchWidgetOffersAction {
  (params, httpClient: IHttpClient): SW.Redux.ThunkAction<Promise<void>>;
}

export const patchWidgetOffersAction: IPatchWidgetOffersAction = (
  params,
  httpClient
) => (dispatch) => {
  dispatch(patchWidgetOffers(params.id));

  return httpClient
    .post('offers', {
      offers: params.offers,
      source: 'offers_widget',
    })
    .then((response) => {
      if (response.success) {
        dispatch(fetchWidgetOffersAction(httpClient)).then(() => {
          dispatch(patchWidgetOffersSuccess(params.id));
        });
        dispatch(fetchAlertAction(httpClient));
      } else {
        dispatch(patchWidgetOffersError(params.id));
      }
      return response;
    });
};
