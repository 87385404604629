import React from 'react';
import { Redirect } from 'react-router-dom';

import { useSelector } from '../../../store';
import Route from './Route';
import type { IRouteProps } from './Route';

const PublicRoute: React.FC<IRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isUserAuthorized = useSelector(
    (state) => !!state.auth.session.current_user
  );
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (!isUserAuthorized) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={
              props.location.state?.backToPrivate ||
              props.location.state?.toPrivate || {
                pathname: '/home',
                state: { from: props.location },
              }
            }
          />
        );
      }}
    />
  );
};

export default PublicRoute;
