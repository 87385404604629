import * as TYPES from './constants';

interface IPageState {
  job: any;
  template: {
    key: any;
    actions: any[];
  };
  isFetching: boolean;
  success: boolean;
  error: any;
  logo_url: any;
}

const initialState: IPageState = {
  job: {},
  template: {
    key: null,
    actions: [],
  },
  isFetching: false,
  success: false,
  error: null,
  logo_url: null,
};

interface IPareReducer {
  (state: IPageState, action): IPageState;
}

const pareReducer: IPareReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.FETCH_PAGE:
      return { ...state, isFetching: true };

    case TYPES.FETCH_PAGE_SUCCESS:
      return { ...state, isFetching: false, ...payload };

    case TYPES.FETCH_PAGE_ERROR:
      return { ...state, isFetching: false, ...payload };

    default:
      return state;
  }
};

export default pareReducer;
