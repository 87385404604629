import * as TYPES from './constants';

interface IEmailCommunicationState {
  isFetching: boolean;
  isPatching: boolean;
  isFetched: boolean;
  unsubscribedStatus: any;
  isUnsubscribedCompletly: boolean;
  instant: boolean;
  subscriptionGroups: any[];
}

const initialState: IEmailCommunicationState = {
  isFetching: false,
  isPatching: false,
  isFetched: false,
  unsubscribedStatus: null,
  isUnsubscribedCompletly: false,
  instant: false,
  subscriptionGroups: [],
};

interface IEmailCommunicationReducer {
  (state: IEmailCommunicationState, action): IEmailCommunicationState;
}

const emailCommunicationReducer: IEmailCommunicationReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case TYPES.PATCH:
      return {
        ...state,
        isPatching: true,
      };

    case TYPES.FETCH_SUCCESS:
      return {
        ...state,
        unsubscribedStatus: payload.email_communications.disabled_status,
        isUnsubscribedCompletly:
          payload.email_communications.disabled_email_notification,
        subscriptionGroups: payload.email_communications.subscription_groups,
        isFetching: false,
        isFetched: true,
      };

    case TYPES.PATCH_SUCCESS:
      return {
        ...state,
        unsubscribedStatus: payload.email_communications.disabled_status,
        isUnsubscribedCompletly:
          payload.email_communications.disabled_email_notification,
        subscriptionGroups: payload.email_communications.subscription_groups,
        instant:
          !state.isUnsubscribedCompletly &&
          payload.email_communications.disabled_email_notification,
        isPatching: false,
      };

    case TYPES.REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
        isPatching: false,
      };

    default:
      return state;
  }
};

export default emailCommunicationReducer;
