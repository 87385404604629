const scrollTo = (selector: string) => (
  opts: ScrollIntoViewOptions = {}
): void => {
  document
    .querySelector(selector)
    .scrollIntoView({ behavior: 'smooth', ...opts });
};
export const scrollToTop = () => scrollTo('#app')({ block: 'start' });

export default scrollTo;
