export const ACTION_KEY = 'applications';

// Sort Filers Params
export const SET_SORT = `${ACTION_KEY}/SET_SORT`;
export const SET_FILTER = `${ACTION_KEY}/SET_FILTER`;
export const SET_COMPANIES = `${ACTION_KEY}/SET_COMPANIES`;
export const SET_LIMIT_N_SKIP = `${ACTION_KEY}/SET_LIMIT_N_SKIP`;
export const SET_INITIAL_PARAMS = `${ACTION_KEY}/SET_INITIAL_PARAMS`;

// Fetch Applications
export const FETCH_APPS = `${ACTION_KEY}/FETCH_APPS`;
export const FETCH_APPS_SUCCESS = `${ACTION_KEY}/FETCH_APPS_SUCCESS`;
export const FETCH_APPS_ERROR = `${ACTION_KEY}/FETCH_APPS_ERROR`;

// Fetch More Applications
export const FETCH_MORE_APPS = `${ACTION_KEY}/FETCH_MORE_APPS`;
export const FETCH_MORE_APPS_SUCCESS = `${ACTION_KEY}/FETCH_MORE_APPS_SUCCESS`;
export const FETCH_MORE_APPS_ERROR = `${ACTION_KEY}/FETCH_MORE_APPS_ERROR`;

// Recent App Action
export const FETCH_RECENT_APP = `${ACTION_KEY}/FETCH_RECENT_APP`;
export const FETCH_RECENT_APP_SUCCESS = `${ACTION_KEY}/FETCH_RECENT_APP_SUCCESS`;
export const FETCH_RECENT_APP_ERROR = `${ACTION_KEY}/FETCH_RECENT_APP_ERROR`;
