import * as TYPES from './constants';

interface IAutoLoginEmailState {
  isSendingAutoLoginLinkEmail: boolean;
}

const initialState: IAutoLoginEmailState = {
  isSendingAutoLoginLinkEmail: false,
};

interface IAutoLoginEmailReducer {
  (state: IAutoLoginEmailState, action): IAutoLoginEmailState;
}

const autoLoginEmailReducer: IAutoLoginEmailReducer = (
  state = initialState,
  { type }
) => {
  switch (type) {
    case TYPES.AUTO_LOGIN:
      return {
        ...state,
        isSendingAutoLoginLinkEmail: true,
      };

    case TYPES.AUTO_LOGIN_SUCCESS:
      return {
        ...state,
        isSendingAutoLoginLinkEmail: false,
      };

    case TYPES.AUTO_LOGIN_ERROR:
      return {
        ...state,
        isSendingAutoLoginLinkEmail: false,
      };

    default:
      return state;
  }
};

export default autoLoginEmailReducer;
