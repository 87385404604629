import type { Breadcrumb, BreadcrumbHint } from '@sentry/types';
import enhanceFetch from './fetch';

interface IEnhanceHttp {
  (breadcrumb: Breadcrumb, hint: BreadcrumbHint): Breadcrumb;
}

const enhanceHttpByCategory: IEnhanceHttp = (breadcrumb, hint) => {
  switch (breadcrumb.category) {
    case 'fetch':
      return enhanceFetch(breadcrumb, hint);
    default:
      return breadcrumb;
  }
};

export default enhanceHttpByCategory;
