import React from 'react';
import cn from 'classnames';
import { Link as OriginLink } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';
import type { LocationDescriptor } from 'history';
import { useRouterRegistry } from '../../../../context/RouterRegistry';
import { useUrlMethods } from '../../../../context/withUrlMethods';

import styles from './Link.pcss';

interface ILinkProps extends LinkProps {
  to: LocationDescriptor;
  disabled?: boolean;
  withouthDecoration?: boolean;
}

const Link: React.FC<ILinkProps> = (props) => {
  const {
    className,
    disabled,
    withouthDecoration,
    onClick,
    ...restProps
  } = props;
  const routerRegistry = useRouterRegistry();
  const { changeLocation } = useUrlMethods();

  const handleHover = () => {
    routerRegistry.preload(props.to);
  };

  const handleClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!disabled) {
      await changeLocation(props.to);
      if (onClick) {
        onClick(e);
      }
    }
  };

  return (
    <OriginLink
      {...restProps}
      className={cn(
        styles.link,
        className,
        { [styles.disabled]: disabled },
        { [styles.withouthDecoration]: withouthDecoration }
      )}
      onMouseOver={handleHover}
      onFocus={handleHover}
      onClick={handleClick}
    />
  );
};

Link.defaultProps = {
  disabled: false,
  withouthDecoration: false,
};

export default Link;
