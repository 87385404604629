import type { SW } from '@types';
import { setBgKey } from './SmartBackgroundContainer/actions';
import { replaceTopSearches } from './TopSearchesContainer/actions';

interface IRememberLocationAction {
  (location): SW.Redux.ThunkAction;
}

const rememberLocationAction: IRememberLocationAction = (location) => (
  dispatch
) => {
  dispatch(setBgKey(location.bg_key));
  if (location.top_searches) {
    dispatch(replaceTopSearches(location.top_searches));
  }
};

export default rememberLocationAction;
