import { createContext } from 'react';
import type { LocationDescriptor } from 'history';
import type {
  ILazyPageRouteProps,
  ILazyPopupRouteProps,
} from '../../components/partials/Router';

export interface IRouterContext {
  pageRegistry: Map<string, ILazyPageRouteProps>;
  popupRegistry: Map<string, ILazyPopupRouteProps>;
  registerPage(path: string, props: ILazyPageRouteProps): void;
  registerPopup(path: string, props: ILazyPopupRouteProps): void;
  preload(path: LocationDescriptor): void;
  load(path: LocationDescriptor): Promise<void>;
}

const defaultRouterContext: IRouterContext = {
  pageRegistry: new Map<string, ILazyPageRouteProps>(),
  popupRegistry: new Map<string, ILazyPopupRouteProps>(),
  registerPage: () => {
    throw Error(`not implemented`);
  },
  registerPopup: () => {
    throw Error(`not implemented`);
  },
  preload: () => {
    throw Error(`not implemented`);
  },
  load: () => {
    throw Error(`not implemented`);
  },
};

const RouterContext = createContext<IRouterContext>(defaultRouterContext);

export default RouterContext;
