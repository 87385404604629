import each from 'lodash/each';

const FILE_TOO_LARGE_ERROR_MSG =
  'The file is too large to keep the limit of 20 megabytes';

const responseToJson = (cookieManager, response) => {
  if (cookieManager) {
    const key = '_headers';
    each(
      response.headers[key]['set-cookie'],
      cookieManager.handleResponseSetCookieHeader
    );
  }

  if (response.status === 413) {
    // TODO[SL]: refactor next steps
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      success: false,
      errors: {
        parsed_text: [FILE_TOO_LARGE_ERROR_MSG],
        file: [FILE_TOO_LARGE_ERROR_MSG],
      },
    });
  }
  if (response.status === 401) {
    // TODO[SL]: refactor next steps
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      success: false,
      errors: { _error: 'You are not authorized to perform this action' },
    });
  }

  if (response.status >= 500) {
    // TODO[SL]: refactor next steps
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      success: false,
      flashes: [{ type: 'error', message: 'Server error, try again' }],
      errors: { _error: 'Server error, try again' },
      isServerError: true,
    });
  }

  return Promise.resolve(response.json());
};

export default responseToJson;
