import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';

import withHttpClient from './context/withHttpClient';
import FullPageLoader from './components/partials/Loader/FullPage';
import { checkSessionAction } from './components/popups/authentication/session/actions';

interface IWithSessionProps {
  current_user?: any;
  httpClient: object;
  isSessionChecked: boolean;
  checkingSession: boolean;
  deletingSession: boolean;
  checkSessionAction(httpClient): any;
}

interface IWithSessionState {
  intervalId?: number;
}

class WithSession extends React.Component<
  IWithSessionProps,
  IWithSessionState
> {
  state = {
    intervalId: null,
  };

  // eslint-disable-next-line react/sort-comp
  handleAuthChange = () => {
    const {
      current_user,
      checkingSession,
      deletingSession,
      httpClient,
    } = this.props;
    if (checkingSession || deletingSession) {
      return;
    }
    const authorized = Cookies.get('authorized') === 'true';
    const authorized_email = Cookies.get('authorized_email');

    if (current_user && !authorized) {
      window.location.reload();
      window.clearInterval(this.state.intervalId);
    }

    if (!current_user && authorized) {
      this.props.checkSessionAction(httpClient);
    }

    if (current_user && current_user.email !== authorized_email) {
      window.location.reload();
      window.clearInterval(this.state.intervalId);
    }
  };

  componentDidMount() {
    this.setState({
      intervalId: window.setInterval(this.handleAuthChange, 250),
    });
  }

  componentWillUnmount() {
    return this.state.intervalId && window.clearInterval(this.state.intervalId);
  }

  render() {
    const { children, isSessionChecked } = this.props;
    if (isSessionChecked) {
      return <>{children}</>;
    }
    return <FullPageLoader />;
  }
}

const mapStateToProps = ({
  auth: {
    session: {
      current_user,
      checkingSession,
      deletingSession,
      isSessionChecked,
    },
  },
}) => ({
  current_user,
  checkingSession,
  deletingSession,
  isSessionChecked,
});

const mapDispatchToProps = {
  checkSessionAction,
};

const frontLoad = (props) => {
  if (props.isSessionChecked) {
    return Promise.resolve();
  }
  return props.checkSessionAction(props.httpClient);
};

export default withHttpClient(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(frontloadConnect(frontLoad)(WithSession))
);
