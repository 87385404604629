import hocableSlim from '../../common/helpers/hocableSlim';
import LayerManagerProvider from './Provider';
import LayerManagerContext from './Context';
import useLayerManager from './use';

const withLayerManager = hocableSlim(LayerManagerProvider);

export {
  LayerManagerProvider as Provider,
  LayerManagerContext as Context,
  withLayerManager,
  useLayerManager,
};
