import { createContext } from 'react';

export interface ICookieManagerContext {
  cookieManager?: any;
}

const defaultCookieManagerContext: ICookieManagerContext = {};

const CookieManagerContext = createContext<ICookieManagerContext>(
  defaultCookieManagerContext
);

export default CookieManagerContext;
