import * as TYPES from './constants';

interface IStrengthState {
  fetching: boolean;
  first: boolean;
  index: any;
  completeness: boolean;
  strength: {
    completeness: number;
    parts: any;
  };
  function_id: number;
  error: any;
}

const initialState: IStrengthState = {
  fetching: false,
  first: true,
  index: null,
  completeness: false,
  strength: {
    completeness: 0,
    parts: {},
  },
  function_id: null,
  error: null,
};

interface IStrengthReducer {
  (state: IStrengthState, action): IStrengthState;
}

const strengthReducer: IStrengthReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH_COMPLETENESS:
      return { ...state, fetching: true };

    case TYPES.FETCH_COMPLETENESS_SUCCESS: {
      let index = null;

      if (state.first) {
        let i = 0;
        const numbers = [1, 2, 3, 4, 5];

        index = payload.count.map((v) => {
          if (v) {
            const number = numbers[i];
            i += 1;
            return number;
          }
          return 0;
        });
      }

      return {
        ...state,
        fetching: false,
        index: index || state.index,
        first: false,
        ...payload.profile,
      };
    }

    case TYPES.FETCH_COMPLETENESS_ERROR:
      return { ...state, fetching: false, ...payload };

    case TYPES.PATCH_COMPLETENESS:
      // TODO: Eslint error should be fixed by refactoring (Denis Shvets)
      // eslint-disable-next-line no-param-reassign
      state.index[payload] = 0;

      return { ...state, patching: true, index: state.index };

    case TYPES.PATCH_COMPLETENESS_SUCCESS:
      return { ...state, patching: false, ...payload.profile };

    case TYPES.PATCH_COMPLETENESS_ERROR:
      return { ...state, patching: false, ...payload };

    case TYPES.CHANGE_COMPLETENESS:
      return { ...state, completeness: payload };

    default:
      return state;
  }
};

export default strengthReducer;
