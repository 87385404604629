import { useEffect, useState } from 'react';
import difference from 'lodash/difference';

type EffectCallback<T extends any> = (diff: {
  added: T[];
  removed: T[];
}) => void | (() => void | undefined);

function useArrDiffEffect<T extends any>(
  effect: EffectCallback<T>,
  dependency: T[]
): void {
  const [prev, setPrev] = useState<T[]>([]);
  useEffect(() => {
    const added = difference(dependency, prev);
    const removed = difference(prev, dependency);
    setPrev(dependency);
    if (added.length || removed.length) {
      return effect({ added, removed });
    }
    return () => {};
  }, [dependency]);
}

export default useArrDiffEffect;
