import React from 'react';
import cn from 'classnames';

import styles from './Header.pcss';

interface IHeaderProps {
  className?: string;
  title: string;
  subtitle?: React.ReactNode | string;
}

const Header: React.FC<IHeaderProps> = (props) => {
  return (
    <div className={cn(styles.header, props.className)}>
      <div className={styles.title}>{props.title}</div>
      {props.subtitle && (
        <div className={styles.subtitle}>{props.subtitle}</div>
      )}
    </div>
  );
};

Header.defaultProps = {
  className: null,
  subtitle: null,
};

export default Header;
