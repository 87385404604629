import React from 'react';

interface IResizeContext {
  isResizing: boolean;
  isResizingHorizontally: boolean;
  isResizingVertically: boolean;
  windowInnerHeight: number;
  windowInnerWidth: number;
}

const INITIAL_VALUE: IResizeContext = {
  isResizing: false,
  isResizingHorizontally: false,
  isResizingVertically: false,
  windowInnerHeight: 0,
  windowInnerWidth: 0,
};

const ResizeContext = React.createContext<IResizeContext>(INITIAL_VALUE);

export default ResizeContext;
