import {
  ADD_TAG_FAILURE,
  ADD_TAG_REQUEST,
  ADD_TAG_SUCCESS,
  FETCH_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  REMOVE_HISTORY_TAG_FAILURE,
  REMOVE_HISTORY_TAG_REQUEST,
  REMOVE_HISTORY_TAG_SUCCESS,
  REMOVE_TAG_FAILURE,
  REMOVE_TAG_REQUEST,
  REMOVE_TAG_SUCCESS,
  SET_FUNC_SPEC_REQUEST,
  SET_FUNC_SPEC_SUCCESS,
  SET_FUNC_SPEC_FAILURE,
  CHANGE_ONLY_REMOTE_JOBS_REQUEST,
  CHANGE_ONLY_REMOTE_JOBS_SUCCESS,
  CHANGE_ONLY_REMOTE_JOBS_FAILURE,
} from './constants';

interface IYourIdeaJobState {
  isFetched: boolean;
  isFetching: boolean;
  jobFunctionId: any;
  jobSpecializationId: any;
  onlyRemoteJobs: boolean;
  dislikedTags: {
    id: number;
    title: string;
  }[];
  historyTags: {
    id: number;
    title: string;
  }[];
  likedTags: {
    id: number;
    title: string;
  }[];
}

const initialState: IYourIdeaJobState = {
  isFetched: false,
  isFetching: false,
  jobFunctionId: null,
  jobSpecializationId: null,
  onlyRemoteJobs: false,
  dislikedTags: [],
  historyTags: [],
  likedTags: [],
};

interface IYourIdeaJobReducer {
  (state: IYourIdeaJobState, action): IYourIdeaJobState;
}

const yourIdeaJobReducer: IYourIdeaJobReducer = (
  state = initialState,
  { payload, type }
) => {
  switch (type) {
    case ADD_TAG_REQUEST:
    case FETCH_REQUEST:
    case REMOVE_HISTORY_TAG_REQUEST:
    case REMOVE_TAG_REQUEST:
    case SET_FUNC_SPEC_REQUEST:
    case CHANGE_ONLY_REMOTE_JOBS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ADD_TAG_FAILURE:
    case REMOVE_HISTORY_TAG_FAILURE:
    case REMOVE_TAG_FAILURE:
    case SET_FUNC_SPEC_FAILURE:
    case CHANGE_ONLY_REMOTE_JOBS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetched: true,
        isFetching: false,
        jobFunctionId: payload.jobFunctionId,
        jobSpecializationId: payload.jobSpecializationId,
        onlyRemoteJobs: payload.onlyRemoteJobs,
        historyTags: payload.historyTags,
        dislikedTags: payload.dislikedTags,
        likedTags: payload.likedTags,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isFetched: true,
        isFetching: false,
      };
    case ADD_TAG_SUCCESS:
    case REMOVE_TAG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dislikedTags: payload.dislikedTags,
        likedTags: payload.likedTags,
      };
    case REMOVE_HISTORY_TAG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        historyTags: payload.historyTags,
      };
    case SET_FUNC_SPEC_SUCCESS:
      return {
        ...state,
        isFetching: false,
        jobFunctionId: payload.jobFunctionId,
        jobSpecializationId: payload.jobSpecializationId,
        dislikedTags: payload.dislikedTags,
        likedTags: payload.likedTags,
      };
    case CHANGE_ONLY_REMOTE_JOBS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        onlyRemoteJobs: payload,
      };
    default:
      return state;
  }
};

export default yourIdeaJobReducer;
