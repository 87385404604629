import { normalizeResume } from '../../../partials/form/inputs/Resume/actions';
import type { IApply } from '../types';
import * as TYPES from './constants';

export interface IExpressApplyState {
  fetched: boolean;
  failed: boolean;
  fetching: boolean;
  patching: boolean;
  apply: IApply;
}

const initialState: IExpressApplyState = {
  fetched: false,
  failed: false,
  fetching: false,
  patching: false,
  apply: null,
};

interface IExpressApplyReducer {
  (state: IExpressApplyState, action): IExpressApplyState;
}

const expressApplyReducer: IExpressApplyReducer = (
  state = initialState,
  { type, payload }
): IExpressApplyState => {
  switch (type) {
    case TYPES.FETCH:
      return {
        ...state,
        fetching: true,
      };

    case TYPES.FETCH_SUCCESS:
      return {
        ...initialState,
        fetched: true,
        fetching: false,
        failed: false,
        apply: {
          ...payload.apply,
          resume: normalizeResume(payload.apply.resume),
        },
      };

    case TYPES.PATCH:
      return {
        ...state,
        patching: true,
      };

    case TYPES.PATCH_SUCCESS:
      return {
        ...state,
        patching: false,
        failed: false,
        apply: payload.apply || state.apply,
      };

    case TYPES.REQUEST_ERROR:
      return {
        ...state,
        fetching: false,
        patching: false,
        failed: true,
      };

    case TYPES.CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default expressApplyReducer;
