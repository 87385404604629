import React from 'react';
import cn from 'classnames';

import styles from './Modal.pcss';

interface IModalProps {
  name: string;
  className?: string;
}

const Modal: React.FC<IModalProps> = ({ name, children, className }) => {
  return (
    <div data-cy={`${name}-modal`} className={cn(styles.modal, className)}>
      {children}
    </div>
  );
};

Modal.defaultProps = {
  className: null,
};

export default Modal;
