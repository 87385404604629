import React from 'react';
import pick from 'lodash/pick';
import useUrlMethods from './useUrlMethods';

interface IHoc<TUrlMethods> {
  <TProps extends TUrlMethods>(
    Component: React.ComponentType<TProps>
  ): React.ComponentType<Omit<TProps, keyof TUrlMethods>>;
}
interface IWithUrlMethods {
  <TMethod extends string, TUrlMethods = { [K in TMethod]: any }>(
    urlMethodNames: TMethod[]
  ): IHoc<TUrlMethods>;
}

const withUrlMethods: IWithUrlMethods = (urlMethodNames = []) => (
  Component
) => {
  const wrapper = (props) => {
    const urlMethods = useUrlMethods();

    const requestedUrlManagerMethods = pick(urlMethods, urlMethodNames);
    const componentProps = {
      ...props,
      ...requestedUrlManagerMethods,
    };

    return <Component {...componentProps} />;
  };
  return wrapper;
};

export default withUrlMethods;
