import * as Sentry from '@sentry/react';
import type { Breadcrumb, BreadcrumbHint } from '@sentry/types';

import { IS_CAPTURE_SENTRY_WARNING_ON_4xx } from '../../../../../../../src/constants';

interface ICaptureEventOnFetch {
  (breadcrumb: Breadcrumb, hint: BreadcrumbHint): void;
}

const captureEventOnFetch: ICaptureEventOnFetch = (
  breadcrumb,
  { input: [url, request], response }
) => {
  if (response && !response.ok) {
    Sentry.withScope((scope) => {
      const message = `${request && request.method} ${url} [${
        response.status
      }]`;
      scope.setFingerprint(['{{ default }}', message]);
      if (response.status >= 500) {
        Sentry.captureMessage(message, Sentry.Severity.Error);
      } else if (IS_CAPTURE_SENTRY_WARNING_ON_4xx) {
        Sentry.captureMessage(message, Sentry.Severity.Info);
      }
    });
  }
};

export default captureEventOnFetch;
