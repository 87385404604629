import * as TYPES from './constants';

interface IPhoneCommunicationState {
  isFetching: boolean;
  isPatching: boolean;
  isFetched: boolean;
  phoneNumber: any;
  smsUpdatesStatus: boolean;
}

const initialState: IPhoneCommunicationState = {
  isFetching: false,
  isPatching: false,
  isFetched: false,
  phoneNumber: null,
  smsUpdatesStatus: false,
};

interface IPhoneCommunicationReducer {
  (state: IPhoneCommunicationState, action): IPhoneCommunicationState;
}

const phoneCommunicationReducer: IPhoneCommunicationReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case TYPES.FETCH_SUCCESS:
      return {
        ...state,
        phoneNumber: payload.communication_preferences.phone,
        smsUpdatesStatus: payload.communication_preferences.sms_status_updates,
        isFetching: false,
        isFetched: true,
      };

    case TYPES.REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
      };

    case TYPES.PATCH:
      return {
        ...state,
        isPatching: true,
      };

    case TYPES.PATCH_SUCCESS:
      return {
        ...state,
        phoneNumber: payload.communication_preferences.phone,
        smsUpdatesStatus: payload.communication_preferences.sms_status_updates,
        isPatching: false,
      };

    default:
      return state;
  }
};

export default phoneCommunicationReducer;
