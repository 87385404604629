import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';

export default (
  initialState,
  history,
  customMiddlewares = [],
  extensions = []
) => {
  // Create a history depending on the environment

  const middlewares = [
    // TODO[SL]: https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument withExtraArgument({ httpClient })
    thunkMiddleware,
    routerMiddleware(history),
    ...customMiddlewares,
  ];

  const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...extensions
  );

  // Create the store
  return createStore(
    createRootReducer(history),
    initialState,
    composedEnhancers
  );
};
