import type { SW } from '@types';
import { SET_BG_KEY, SET_BG_URL } from './constants';
import * as smartBackgrounds from './SmartBackground/images';

const setBgUrl = (bg_url) => ({ type: SET_BG_URL, payload: bg_url });
export const setBgKey = (bg_key) => ({ type: SET_BG_KEY, payload: bg_key });

interface ILoadNewBackground {
  (bgKey): SW.Redux.ThunkAction;
}

export const loadNewBackground: ILoadNewBackground = (bgKey) => (dispatch) => {
  const imgSrc = smartBackgrounds[bgKey] || smartBackgrounds.unknown;

  if (imgSrc) {
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      dispatch(setBgUrl(imgSrc));
    };
  }
};
