import React from 'react';

import styles from './Overlay.pcss';

interface IOverlayProps {
  className?: string;
}

const Overlay: React.FC<IOverlayProps> = ({ children, className }) => {
  return (
    <>
      <div className={styles.overlay} />
      <div className={className}>{children}</div>
    </>
  );
};

Overlay.defaultProps = {
  className: null,
};

export default Overlay;
