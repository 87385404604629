export const ACTION_KEY = 'application';

// Fetch Application
export const FETCH_REQUEST = `${ACTION_KEY}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${ACTION_KEY}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${ACTION_KEY}/FETCH_FAILURE`;

// Remove Application
export const REMOVE_REQUEST = `${ACTION_KEY}/REMOVE_REQUEST`;
export const REMOVE_SUCCESS = `${ACTION_KEY}/REMOVE_SUCCESS`;
export const REMOVE_FAILURE = `${ACTION_KEY}/REMOVE_FAILURE`;

// Update Application
export const UPDATE_REQUEST = `${ACTION_KEY}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${ACTION_KEY}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${ACTION_KEY}/UPDATE_FAILURE`;

export const UPDATE_APP_AFTER_CREATE_NOTE = `${ACTION_KEY}/UPDATE_APP_AFTER_CREATE_NOTE`;
export const UPDATE_APP_AFTER_UPDATE_STATUS = `${ACTION_KEY}/UPDATE_APP_AFTER_UPDATE_STATUS`;
export const UPDATE_APP_AFTER_CREATE_REMINDER = `${ACTION_KEY}/UPDATE_APP_AFTER_CREATE_REMINDER`;
export const UPDATE_APP_AFTER_REMOVE_REMINDER = `${ACTION_KEY}/UPDATE_APP_AFTER_REMOVE_REMINDER`;
export const UPDATE_APP_AFTER_REMOVE_NOTE = `${ACTION_KEY}/UPDATE_APP_AFTER_REMOVE_NOTE`;
