import { PUSH_NOTIFICATION, HIDE_NOTIFICATIONS } from './constants';

export const pushNotificationAction = (params) => ({
  type: PUSH_NOTIFICATION,
  payload: params,
});
export const hideNotificationAction = (params) => ({
  type: HIDE_NOTIFICATIONS,
  payload: params,
});
