import React, { useState } from 'react';
import type { LocationDescriptor } from 'history';
import RouterContext from './Context';
import { getMatchedPages, getMatchedPopups } from './helpers';
import type {
  ILazyPageRouteProps,
  ILazyPopupRouteProps,
} from '../../components/partials/Router';
import { useAppMetadata } from '../AppMetadata';

const RouterRegistryProvider: React.FC = ({ children }) => {
  const appMetadata = useAppMetadata();
  const [pageRegistry, setPageRegistry] = useState<
    Map<string, ILazyPageRouteProps>
  >(new Map<string, any>());
  const [popupRegistry, setPopupRegistry] = useState<
    Map<string, ILazyPopupRouteProps>
  >(new Map<string, any>());

  const registerPage = (path, params) => {
    pageRegistry.set(path, params);
    setPageRegistry(new Map(pageRegistry));
  };

  const registerPopup = (path, params) => {
    popupRegistry.set(path, params);
    setPopupRegistry(new Map(popupRegistry));
  };

  const preload = (path: LocationDescriptor) => {
    const matchedPages = getMatchedPages(pageRegistry, path);
    const matchedPopups = getMatchedPopups(popupRegistry, path);

    if (matchedPages.length) {
      matchedPages.forEach((c) => c.preload());
    }
    if (matchedPopups) {
      matchedPopups.forEach((c) => c.preload());
    }
  };

  const load = async (path: LocationDescriptor): Promise<void> => {
    const matchedPages = getMatchedPages(pageRegistry, path);
    const matchedPopups = getMatchedPopups(popupRegistry, path);

    const promises = [];

    if (matchedPages.length) {
      const pageLoaders = matchedPages.map((c) => c.load());
      const pageLoader = Promise.all(pageLoaders);
      appMetadata.trackChunksFetching(pageLoader);
      promises.push(pageLoader);
    }
    if (matchedPopups.length) {
      const popupLoaders = matchedPopups.map((c) => c.load());
      const popupLoader = Promise.all(popupLoaders);
      appMetadata.trackChunksFetching(popupLoader);
      promises.push(popupLoader);
    }

    return Promise.all(promises).then();
  };

  return (
    <RouterContext.Provider
      value={{
        pageRegistry,
        popupRegistry,
        registerPage,
        registerPopup,
        preload,
        load,
      }}
    >
      {children}
    </RouterContext.Provider>
  );
};

export default RouterRegistryProvider;
