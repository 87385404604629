import type { Dispatch } from 'redux';
import httpRequest from './httpRequest';

class HttpClient {
  constructor(
    private dispatch: Dispatch,
    private changeLocation: any,
    private cookieManager?: any,
    private customUserAgent?: string
  ) {}

  request = (
    method,
    action,
    params,
    body = null,
    headers: HeadersInit = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  ) =>
    httpRequest(
      this.dispatch,
      this.changeLocation,
      this.cookieManager,
      this.customUserAgent,
      method,
      action,
      params,
      body,
      headers
    );

  file = (action, data) =>
    this.request('POST', action, {}, data, {
      Accept: 'application/json',
    });

  post = (action, data) =>
    this.request('POST', action, {}, JSON.stringify(data));

  put = (action, data) => this.request('PUT', action, {}, JSON.stringify(data));

  patch = (action, data) =>
    this.request('PATCH', action, {}, JSON.stringify(data));

  delete = (action, data = {}) =>
    this.request('DELETE', action, {}, JSON.stringify(data));

  get = (action, params = {}) => this.request('GET', action, params);
}

export default HttpClient;
