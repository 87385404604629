import * as TYPES from './constants';

interface IActionState {
  isFetching: boolean;
  isPatching: boolean;
  popup: any;
  errors: any;
}

const initialState: IActionState = {
  isFetching: false,
  isPatching: false,
  popup: null,
  errors: null,
};

interface IActionReducer {
  (state: IActionState, action): IActionState;
}

const actionReducer: IActionReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.RESET_ACTION:
      return initialState;

    case TYPES.FETCH_ACTION:
      return { ...state, isFetching: true };

    case TYPES.FETCH_ACTION_SUCCESS:
      return { ...state, isFetching: false };

    case TYPES.FETCH_ACTION_ERROR:
      return { ...state, isFetching: false, errors: payload.errors };

    case TYPES.PATCH_ACTION:
      return { ...state, isPatching: true };

    case TYPES.PATCH_ACTION_SUCCESS:
      return { ...state, isPatching: false };

    case TYPES.PATCH_ACTION_ERROR:
      return { ...state, isPatching: false, errors: payload.errors };

    case TYPES.SHOW_POPUP:
      return {
        ...state,
        popup: {
          popup_id: payload.popup_id,
          rows: payload.rows,
        },
      };

    default:
      return state;
  }
};

export default actionReducer;
