import orderBy from 'lodash/orderBy';

import * as TYPES from './constants';

interface ICompanyPageState {
  isCompanyLoading: boolean;
  company: any;
  areRecentlyPostedJobsLoading: boolean;
  areRecentlyPostedJobsLoaded: boolean;
  recentlyPostedJobs: any[];
  areIndustryCompaniesLoading: boolean;
  industryCompanies: any;
}

const initialState: ICompanyPageState = {
  isCompanyLoading: false,
  company: null,
  areRecentlyPostedJobsLoading: false,
  areRecentlyPostedJobsLoaded: false,
  recentlyPostedJobs: [],
  areIndustryCompaniesLoading: false,
  industryCompanies: null,
};

interface ICompanyPageReducer {
  (state: ICompanyPageState, action): ICompanyPageState;
}

const companyPageReducer: ICompanyPageReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.LOAD_COMPANY:
      return { ...state, isCompanyLoading: true };
    case TYPES.LOAD_COMPANY_SUCCESS:
      return { ...state, isCompanyLoading: false, company: payload };
    case TYPES.LOAD_COMPANY_ERROR:
      return { ...state, isCompanyLoading: false, company: null };

    case TYPES.LOAD_RECENTLY_POSTED_JOBS:
      return { ...state, areRecentlyPostedJobsLoading: true };
    case TYPES.LOAD_RECENTLY_POSTED_JOBS_SUCCESS:
      return {
        ...state,
        areRecentlyPostedJobsLoading: false,
        areRecentlyPostedJobsLoaded: true,
        recentlyPostedJobs: payload.slice(0, 10),
      };
    case TYPES.LOAD_RECENTLY_POSTED_JOBS_ERROR:
      return {
        ...state,
        areRecentlyPostedJobsLoading: false,
        areRecentlyPostedJobsLoaded: true,
        recentlyPostedJobs: [],
      };

    case TYPES.LOAD_INDUSTRY_COMPANIES:
      return { ...state, areIndustryCompaniesLoading: true };
    case TYPES.LOAD_INDUSTRY_COMPANIES_SUCCESS:
      return {
        ...state,
        areIndustryCompaniesLoading: false,
        industryCompanies: orderBy(payload, ['popularity_score']).slice(0, 19),
      };

    case TYPES.LOAD_INDUSTRY_COMPANIES_ERROR:
      return {
        ...state,
        areIndustryCompaniesLoading: false,
        industryCompanies: null,
      };

    default:
      return state;
  }
};

export default companyPageReducer;
