import Dialog from '../components/partials/Dialog';

export const alert = ({ title, subtitle, button }) =>
  Dialog.showAlert({ title, subtitle, button });

interface IConfirmProps {
  title: string;
  subtitle?: React.ReactNode;
  acceptButton: string;
  declineButton: string;
}

export const confirm = ({
  title,
  subtitle,
  acceptButton,
  declineButton,
}: IConfirmProps) =>
  Dialog.showConfirm({ title, subtitle, acceptButton, declineButton });

export default {
  alert,
  confirm,
};
