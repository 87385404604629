import React from 'react';

import {
  PageSwitch,
  Route,
  PublicRoute,
  PrivateRoute,
} from './components/partials/Router';

import AboutLazyPage from './components/pages/About';
import CompanyLazyPage from './components/pages/Company';
import TopCompaniesHiringLazyPage from './components/pages/TopCompaniesHiring';
import ContactLazyPage from './components/pages/Contact';
import PartnershipsLazyPage from './components/pages/Partnerships';
import AdvertiseLazyPage from './components/pages/Advertise';
import NotFoundLazyPage from './components/pages/NotFound';
import PolicyLazyPage from './components/pages/Policy';
import TermsLazyPage from './components/pages/Terms';
import ConsumerRequestLazyPage from './components/pages/ConsumerRequest';
import LogOutLazyPage from './components/pages/LogOut';
import JobsUALazyPage from './components/pages/JobsUa';
import JobAlertLazyPage from './components/pages/JobAlert';
import OfferLazyPage from './components/pages/Offer';
import OopsLazyPage from './components/pages/Oops';

import RegLazyPage from './components/pages/Reg';
import LandingLazyPage from './components/pages/Landing';
import HomeLazyPage from './components/pages/Home';
import JobLazyPage from './components/pages/Job';
import JobSearchLazyPage from './components/pages/JobSearch';
import JobRecommendationsLazyPage from './components/pages/JobRecommendations';

import ApplicationLazyPage from './components/pages/App';
import ApplicationsLazyPage from './components/pages/Apps';
import ProfileLazyPage from './components/pages/Profile';
import CareerResourcesLazyPage from './components/pages/CareerResources';
import CareersLazyPage from './components/pages/CareersPage';

const Routes: React.FC = () => (
  <PageSwitch>
    <PrivateRoute
      path="/logout"
      component={LogOutLazyPage}
      exact
      isRestorable={false}
    />
    <Route path="/reg/:flow/:hash" component={RegLazyPage} />

    <PrivateRoute path="/home" component={HomeLazyPage} exact />

    <PrivateRoute path="/application/:id" component={ApplicationLazyPage} />
    <PrivateRoute path="/applications" component={ApplicationsLazyPage} exact />
    <PrivateRoute path="/profile" component={ProfileLazyPage} />
    <PrivateRoute
      path="/jobs/:id/job-alert"
      component={JobAlertLazyPage}
      exact
    />

    <Route path="/jobs/:id" component={JobLazyPage} exact />
    <Route
      path="/jobs/:location/:positionWithId"
      component={JobsUALazyPage}
      exact
    />

    <Route path="/job/search" component={JobSearchLazyPage} exact />
    <PrivateRoute
      path="/job/recommendations"
      component={JobRecommendationsLazyPage}
      exact
    />

    <PrivateRoute
      path="/offers/:id"
      component={OfferLazyPage}
      getParams={({ params }) => ({
        id: parseInt(params.id, 10),
      })}
    />

    <PrivateRoute
      path="/career-resources"
      component={CareerResourcesLazyPage}
      exact
    />
    <PrivateRoute
      path="/careers/:company/:title/:location?"
      component={CareersLazyPage}
    />

    <Route
      path="/job-applications/:industry/:company"
      component={CompanyLazyPage}
    />
    <Route
      path="/job-applications/:industry?"
      component={TopCompaniesHiringLazyPage}
      getParams={({ params }) => ({
        industry: params.industry,
      })}
    />
    <Route path="/partnerships" component={PartnershipsLazyPage} exact />
    <Route path="/about" component={AboutLazyPage} exact />
    <Route path="/contact" component={ContactLazyPage} exact />
    <Route path="/advertise" component={AdvertiseLazyPage} exact />
    <Route path="/policy" component={PolicyLazyPage} exact />
    <Route path="/terms" component={TermsLazyPage} exact />
    <Route path="/oops" component={OopsLazyPage} exact />
    <Route path="/consumer-request" component={ConsumerRequestLazyPage} exact />

    <PublicRoute path="/" component={LandingLazyPage} exact />

    <Route path="*" component={NotFoundLazyPage} />
  </PageSwitch>
);

export default Routes;
