import { combineReducers } from 'redux';

import { ACTION_KEY as SBC_ACTION_KEY } from './SmartBackgroundContainer/constants';
import smartBackgroundReducer from './SmartBackgroundContainer/reducer';
import { ACTION_KEY as TSC_ACTION_KEY } from './TopSearchesContainer/constants';
import topSearchesReducer from './TopSearchesContainer/reducer';

export default combineReducers({
  [SBC_ACTION_KEY]: smartBackgroundReducer,
  [TSC_ACTION_KEY]: topSearchesReducer,
});
