import 'core-js';
import 'core-js/modules/es.weak-map';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { loadableReady } from '@loadable/component';
import nprogress from 'nprogress';
import { createClientStoreAndBrowserHistory } from '../src/store';
import initClient from '../src/@root/client';
import Size from '../src/utils/size';
import initServices from './initServices';

const { store, browserHistory } = createClientStoreAndBrowserHistory();
initServices(store, browserHistory);
const client = initClient(store, browserHistory);
const container = document.getElementById('app');
const perform = container.hasChildNodes() ? ReactDOM.hydrate : ReactDOM.render;
nprogress.configure({ minimum: 0.1, showSpinner: Size.isDesktop() });

loadableReady(() => {
  perform(client, container);
});

smoothscroll.polyfill();
