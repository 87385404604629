import Cookies from 'js-cookie';
import { COOKIE_LOCATION } from './constants';

export const getCookieLocation = () => {
  try {
    const cookieLocation = Cookies.get(COOKIE_LOCATION);
    return JSON.parse(cookieLocation);
  } catch (error) {
    return null;
  }
};
