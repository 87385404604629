import { v4 as uuidv4 } from 'uuid';
import without from 'lodash/without';
import type { SW } from '@types';
import {
  PUSH_NOTIFICATION,
  HIDE_NOTIFICATIONS,
  CLEAR_NOTIFICATION,
} from './constants';

const DEFAULT_DURATION = 5000;

type INotificationsState = SW.Models.IFlashNotification[];

const initialState: INotificationsState = [];

interface INotificationsReducer {
  (state: INotificationsState, action): INotificationsState;
}

const notificationsReducer: INotificationsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PUSH_NOTIFICATION:
      return [
        ...state,
        {
          uuid: uuidv4(),
          duration: DEFAULT_DURATION,
          ...action.payload,
        },
      ];
    case HIDE_NOTIFICATIONS:
      return without(state, action.payload);
    case CLEAR_NOTIFICATION:
      return [];
    default:
      return state;
  }
};

export default notificationsReducer;
