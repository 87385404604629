import React from 'react';

interface ISafeContentProps {
  as?: React.ElementType;
  children: string;
  className?: string;
}

const SafeContent: React.FC<ISafeContentProps> = (props) => {
  const { as: Component, children, className } = props;

  // eslint-disable-next-line react/no-danger
  return (
    <Component
      className={className}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};

SafeContent.defaultProps = {
  as: 'div',
  className: null,
};

export default SafeContent;
