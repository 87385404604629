import * as TYPES from './constants';
import type { ICcpaOptions } from './ICcpaOptions';

const initialState: ICcpaOptions = null;

interface ICcpaOptionsReducer {
  (state: ICcpaOptions, action): ICcpaOptions;
}

const ccpaOptionsReducer: ICcpaOptionsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.SET:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default ccpaOptionsReducer;
