export const POPUP_KEY = 'additional-questions';
export const ACTION_KEY = 'additional-questions';

// Additional Questions
export const FETCH_ADDITIONAL_QUESTIONS = `${ACTION_KEY}/FETCH_ADDITIONAL_QUESTIONS`;
export const FETCH_ADDITIONAL_QUESTIONS_SUCCESS = `${ACTION_KEY}/FETCH_ADDITIONAL_QUESTIONS_SUCCESS`;
export const FETCH_ADDITIONAL_QUESTIONS_ERROR = `${ACTION_KEY}/FETCH_ADDITIONAL_QUESTIONS_ERROR`;

export const PATCH_ADDITIONAL_QUESTIONS = `${ACTION_KEY}/PATCH_ADDITIONAL_QUESTIONS`;
export const PATCH_ADDITIONAL_QUESTIONS_SUCCESS = `${ACTION_KEY}/PATCH_ADDITIONAL_QUESTIONS_SUCCESS`;
export const PATCH_ADDITIONAL_QUESTIONS_ERROR = `${ACTION_KEY}/PATCH_ADDITIONAL_QUESTIONS_ERROR`;

export const CLEAR_ADDITIONAL_QUESTIONS = `${ACTION_KEY}/CLEAR_ADDITIONAL_QUESTIONS`;

export const SKIP_ADDITIONAL_QUESTIONS = `${ACTION_KEY}/SKIP_ADDITIONAL_QUESTIONS`;
