import { SET_BG_KEY, SET_BG_URL } from './constants';

interface ISmartBackgroundState {
  bg_key: string;
  bg_url: string;
}

const initialState: ISmartBackgroundState = {
  bg_key: null,
  bg_url: null,
};

interface ISmartBackgroundReducer {
  (state: ISmartBackgroundState, action): ISmartBackgroundState;
}

const smartBackgroundReducer: ISmartBackgroundReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_BG_KEY:
      return { ...state, bg_key: action.payload };
    case SET_BG_URL:
      return { ...state, bg_url: action.payload };
    default:
      return state;
  }
};

export default smartBackgroundReducer;
