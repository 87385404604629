import React from 'react';
import CookieManagerContext from './Context';

interface ICookieManagerProviderProps {
  cookieManager: any;
}

const CookieManagerProvider: React.FC<ICookieManagerProviderProps> = (
  props
) => {
  const { children, cookieManager } = props;
  return (
    <CookieManagerContext.Provider
      value={{
        cookieManager,
      }}
    >
      {children}
    </CookieManagerContext.Provider>
  );
};

export default CookieManagerProvider;
