import React, { useMemo } from 'react';

import { useDispatch } from '../../store';
import { useUrlMethods } from '../withUrlMethods';
import { useCookieManager } from '../CookieManager';
import { useUserAgent } from '../UserAgent';

import HttpClient from './HttpClient';
import HttpClientContext from './HttpClientContext';

const HttpClientProvider: React.FC = (props) => {
  const dispatch = useDispatch();
  const { changeLocation } = useUrlMethods();
  const { cookieManager } = useCookieManager();
  const { userAgent } = useUserAgent();

  const httpClient = useMemo(
    () => new HttpClient(dispatch, changeLocation, cookieManager, userAgent),
    [dispatch, cookieManager, userAgent]
  );

  return (
    <HttpClientContext.Provider value={httpClient}>
      {props.children}
    </HttpClientContext.Provider>
  );
};

export default HttpClientProvider;
