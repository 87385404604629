import * as TYPES from './constants';

interface IRegState {
  fetching: boolean;
  fetchingReg: boolean;
  success: boolean;
  errors: any;
  page: {
    form_values: any;
    header_parts: any[];
    sub_header_text: string;
  };
}

const initialState: IRegState = {
  fetching: false,
  fetchingReg: false,
  success: false,
  errors: null,
  page: {
    form_values: {},
    header_parts: [],
    sub_header_text: '',
  },
};

interface IRegReducer {
  (state: IRegState, action): IRegState;
}

const regReducer: IRegReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.REG_SIGN_UP:
      return { ...state, fetching: true };

    case TYPES.REG_SIGN_UP_SUCCESS:
      return { ...state, fetching: false };

    case TYPES.REG_SIGN_UP_ERROR:
      return { ...state, fetching: false, success: payload.success };

    case TYPES.REG:
      return { ...state, fetchingReg: true };

    case TYPES.REG_SUCCESS:
      return { ...state, fetchingReg: false, ...payload };

    case TYPES.REG_ERROR:
      return { ...state, fetchingReg: false, ...payload };

    default:
      return state;
  }
};

export default regReducer;
