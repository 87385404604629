import { combineReducers } from 'redux';

import { ACTION_KEY as PAGE_KEY } from './WithPage/constants';
import pareReducer from './WithPage/reducer';
import { ACTION_KEY } from './popups/Action/constants';
import actionReducer from './popups/Action/reducer';
import { ACTION_KEY as WIDGET_KEY } from './popups/Action/widgets/constants';
import actionWidgetsReducer from './popups/Action/widgets/reducer';
import { ACTION_KEY as SIMILAR_JOBS_KEY } from './useSimilarJobs/constants';
import similarJobsReducer from './useSimilarJobs/reducer';

export default combineReducers({
  [PAGE_KEY]: pareReducer,
  [SIMILAR_JOBS_KEY]: similarJobsReducer,
  [ACTION_KEY]: actionReducer,
  [WIDGET_KEY]: actionWidgetsReducer,
});
