import defaultTopSearches from '../../../../../caches/default_top_searches.json';

import { SET_TOP_SEARCHES } from './constants';

interface ITopSearchesState {
  [key: string]: {
    // param: 'query' | 'location_title';
    param: string;
    queries: string[];
  };
}

const initialState: ITopSearchesState = defaultTopSearches;

interface ITopSearchesReducer {
  (state: ITopSearchesState, action): ITopSearchesState;
}

const topSearchesReduces: ITopSearchesReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_TOP_SEARCHES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default topSearchesReduces;
