export const ACTION_KEY = 'profile';

export const FETCH_COMPLETENESS = `${ACTION_KEY}/FETCH_COMPLETENESS`;
export const FETCH_COMPLETENESS_SUCCESS = `${ACTION_KEY}/FETCH_COMPLETENESS_SUCCESS`;
export const FETCH_COMPLETENESS_ERROR = `${ACTION_KEY}/FETCH_COMPLETENESS_ERROR`;

export const PATCH_COMPLETENESS = `${ACTION_KEY}/PATCH_COMPLETENESS`;
export const PATCH_COMPLETENESS_SUCCESS = `${ACTION_KEY}/PATCH_COMPLETENESS_SUCCESS`;
export const PATCH_COMPLETENESS_ERROR = `${ACTION_KEY}/PATCH_COMPLETENESS_ERROR`;

export const CHANGE_COMPLETENESS = `${ACTION_KEY}/CHANGE_COMPLETENESS`;

export const LOCAL_STORAGE_COMPLETENESS = 'completeness';
