import * as TYPES from './constants';

interface ITopCompaniesHiringPageState {
  areIndustriesLoading: boolean;
  industries: any;
}

const topCompaniesHiringPageState = {
  areIndustriesLoading: false,
  industries: null,
};

interface ITopCompaniesHiringPageReducer {
  (state: ITopCompaniesHiringPageState, action): ITopCompaniesHiringPageState;
}

const topCompaniesHiringPageReducer: ITopCompaniesHiringPageReducer = (
  state = topCompaniesHiringPageState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.LOAD_INDUSTRIES:
      return { ...state, areIndustriesLoading: true };
    case TYPES.LOAD_INDUSTRIES_SUCCESS:
      return { ...state, areIndustriesLoading: false, industries: payload };
    case TYPES.LOAD_INDUSTRIES_ERROR:
      return { ...state, areIndustriesLoading: false, industries: null };

    default:
      return state;
  }
};

export default topCompaniesHiringPageReducer;
