import React from 'react';
import cn from 'classnames';

import styles from './Dots.pcss';

interface IDotsProps {
  className?: string;
  active?: boolean;
}

const Dots: React.FC<IDotsProps> = (props) => {
  const { className, active, children } = props;
  return (
    <div className={cn(styles.container, className)}>
      <div className={cn({ [styles.hidden]: active })}>{children}</div>
      <div className={cn(styles.spinner, { [styles.hidden]: !active })}>
        <span className={styles.bounce}>⬤</span>
        <span className={styles.bounce}>⬤</span>
        <span className={styles.bounce}>⬤</span>
      </div>
    </div>
  );
};

Dots.defaultProps = {
  className: undefined,
  active: true,
};

export default Dots;
