import type { SW } from '@types';
import {
  loadState,
  throttledSaveToLocalStorage,
} from '../../../../utils/local_storage';

import { LOCAL_STORAGE_TOP_SEARCHES_KEY } from '../../../../utils/local_storage/constants';
import { SET_TOP_SEARCHES } from './constants';

const setTopSeaches = (top_searches) => ({
  type: SET_TOP_SEARCHES,
  payload: top_searches,
});

interface IReplaceTopSearches {
  (top_searches): SW.Redux.ThunkAction;
}

export const replaceTopSearches: IReplaceTopSearches = (top_searches) => (
  dispatch
) => {
  throttledSaveToLocalStorage(LOCAL_STORAGE_TOP_SEARCHES_KEY, top_searches);
  dispatch(setTopSeaches(top_searches));
};

interface ILoadSavedTopSearches {
  (): SW.Redux.ThunkAction;
}

export const loadSavedTopSearches: ILoadSavedTopSearches = () => (dispatch) => {
  const savedTopSeaches = loadState(LOCAL_STORAGE_TOP_SEARCHES_KEY);
  if (savedTopSeaches) {
    dispatch(setTopSeaches(savedTopSeaches));
  }
};
