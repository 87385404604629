export const ACTION_KEY = 'AutoStatusUpdates';

// Auto Track
export const FETCH_ATS_SETTINGS = `${ACTION_KEY}/FETCH_ATS_SETTINGS`;
export const FETCH_ATS_SETTINGS_SUCCESS = `${ACTION_KEY}/FETCH_ATS_SETTINGS_SUCCESS`;
export const FETCH_ATS_SETTINGS_ERROR = `${ACTION_KEY}/FETCH_ATS_SETTINGS_ERROR`;

export const PATCH_ATS_SETTINGS = `${ACTION_KEY}/PATCH_ATS_SETTINGS`;
export const PATCH_ATS_SETTINGS_SUCCESS = `${ACTION_KEY}/PATCH_ATS_SETTINGS_SUCCESS`;
export const PATCH_ATS_SETTINGS_ERROR = `${ACTION_KEY}/PATCH_ATS_SETTINGS_ERROR`;

export const REMOVE_APP_FROM_ATS = `${ACTION_KEY}/REMOVE_APP_FROM_ATS`;

export const UPDATE_ATS_SETTINGS = `${ACTION_KEY}/UPDATE_ATS_SETTINGS`;
export const CLEAR_ATS_SETTINGS = `${ACTION_KEY}/CLEAR_ATS_SETTINGS`;
