import ReactGTM from 'react-gtm-module';

export const SETUP_GTM = 'SETUP_GTM';

const gtmMiddleware = () => (next) => (action) => {
  if (process.env.GTM_ID && action.type === SETUP_GTM) {
    ReactGTM.initialize({
      gtmId: process.env.GTM_ID,
      dataLayer: action.payload,
    });
  }

  return next(action);
};

export default gtmMiddleware;
