import { combineReducers } from 'redux';

import { ACTION_KEY as PROFILE_DETAILS_ACTION_KEY } from './ProfileDetails/useProfileDetails/constants';
import profileDetailsReducer from './ProfileDetails/useProfileDetails/reducer';
import { ACTION_KEY as PROFILE_LOCATION_ACTION_KEY } from './ProfileLocation/useProfileLocation/constants';
import profileLocationReducer from './ProfileLocation/useProfileLocation/reducer';

export default combineReducers({
  [PROFILE_DETAILS_ACTION_KEY]: profileDetailsReducer,
  [PROFILE_LOCATION_ACTION_KEY]: profileLocationReducer,
});
