import * as TYPES from './constants';

interface IAppMetadataState {
  isSsrUsed: boolean;
  renderedBy: 'browser' | 'server';
}

const initialState: IAppMetadataState = {
  isSsrUsed: !process.env.BROWSER,
  renderedBy: process.env.BROWSER ? 'browser' : 'server',
};

interface IAppMetadataReducer {
  (state: IAppMetadataState, action): IAppMetadataState;
}

const appMetadataReducer: IAppMetadataReducer = (
  state = initialState,
  { type }
) => {
  switch (type) {
    case TYPES.UPDATE_APP_METADATA:
      return {
        ...state,
        renderedBy: process.env.BROWSER ? 'browser' : 'server',
      };

    default:
      return state;
  }
};

export default appMetadataReducer;
