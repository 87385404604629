import { useContext } from 'react';
import HttpClientContext from './HttpClientContext';
import HttpClientProvider from './HttpClientProvider';
import { IHttpClient } from './HttpClient';
import withHttpClient from './withHttpClient';

const useHttpClient = () => useContext<IHttpClient>(HttpClientContext);

export default withHttpClient;
export { HttpClientProvider, useHttpClient };

export type { IHttpClient };
