import * as TYPES from './constants';

interface IOffersState {
  fetching: boolean;
  patching: boolean;
  offers: any[];
  error: any;
}

const initialState: IOffersState = {
  fetching: false,
  patching: false,
  offers: [],
  error: null,
};

interface IOffersReducer {
  (state: IOffersState, action): IOffersState;
}

const offersReducer: IOffersReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH_WIDGET_OFFERS:
      return { ...state, fetching: true };

    case TYPES.FETCH_WIDGET_OFFERS_SUCCESS:
      return { ...state, fetching: false, offers: payload.offers };

    case TYPES.FETCH_WIDGET_OFFERS_ERROR:
      return { ...state, fetching: false, ...payload };

    case TYPES.PATCH_WIDGET_OFFERS:
      return { ...state, patching: payload };

    case TYPES.PATCH_WIDGET_OFFERS_SUCCESS:
      return { ...state, patching: false };

    case TYPES.PATCH_WIDGET_OFFERS_ERROR:
      return { ...state, patching: false };

    default:
      return state;
  }
};

export default offersReducer;
