import logo from './components/partials/Logo/logo-blue-256.png';

export const COMPANY_NAME = 'StartWire';

export const IS_PARTNER_AGREEMENTS_ENABLED = true;

export const IS_CAPTURE_SENTRY_ERRROR_ON_UNSUCCEED_SCRIPT_LOADING = false;
export const IS_CAPTURE_SENTRY_WARNING_ON_4xx = true;

export const MAX_MOBILE_WIDTH = 767;
export const MIN_DESKTOP_WIDTH = 1136;

export const WIDE_BANNER_ENOUGH_SPACE_WIDTH = 776;

export const DROPBOX_APP_KEY = 'judt0bhkcdzo5su';
export const GOOGLE_DRIVE_API_KEY = 'AIzaSyBWgOGih7L_r5Vx2m7APwnmxqI9mhlTlI0';
export const GOOGLE_DRIVE_CLIENT_ID =
  '703156268671-3mev0v42k18viqi3hj016p6gmo3gutgf';

export const SCHEMA_ORG = {
  Organization: JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'StartWire',
    url: `${process.env.HOST}/`,
    logo,
  }),
  WebSite: JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `${process.env.HOST}/`,
    potentialAction: [
      {
        '@type': 'SearchAction',
        target: `${process.env.HOST}/job/search?query={search_term_string}`,
        'query-input': 'required name=search_term_string',
      },
    ],
  }),
};

export const srcSet2xSizes = `(-webkit-min-device-pixel-ratio: 2) 2x,
                              (min--moz-device-pixel-ratio: 2) 2x,
                              (-o-min-device-pixel-ratio: 2/1) 2x,
                              (min-device-pixel-ratio: 2) 2x,
                              (min-resolution: 192dpi) 2x,
                              (min-resolution: 2dppx) 2x,
                              1x`;

export const doNothing = () => {};
