export const ACTION_KEY = 'offer';

export const FETCH_OFFER_PAGE = `${ACTION_KEY}/FETCH_OFFER_PAGE`;
export const FETCH_OFFER_PAGE_SUCCESS = `${ACTION_KEY}/FETCH_OFFER_PAGE_SUCCESS`;
export const FETCH_OFFER_PAGE_ERROR = `${ACTION_KEY}/FETCH_OFFER_PAGE_ERROR`;

export const APPLY_OFFER_PAGE = `${ACTION_KEY}/APPLY_OFFER_PAGE`;
export const APPLY_OFFER_PAGE_SUCCESS = `${ACTION_KEY}/APPLY_OFFER_PAGE_SUCCESS`;
export const APPLY_OFFER_PAGE_ERROR = `${ACTION_KEY}/APPLY_OFFER_PAGE_ERROR`;

export const APPLY_RESUME = `${ACTION_KEY}/APPLY_RESUME`;
