import { createMemoryHistory } from 'history';

import createStore from './createStore';

const createServerStore = (entryUrl) => {
  const memoryHistory = createMemoryHistory({
    initialEntries: [entryUrl],
  });
  return createStore({}, memoryHistory);
};

export default createServerStore;
