import React, { useState } from 'react';
import cn from 'classnames';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';
import { SW } from '@types';

import Dots from '../Dots';

import styles from './Button.pcss';

type IButtonOwnProps = {
  className?: string;
  size?: SW.Styles.ISize;
  theme?: SW.Styles.ITheme;
  dots?: boolean;
} & (
  | {
      onClick?(...args): Promise<void>;
      handleDots: true;
      handleDisabling?: boolean;
    }
  | {
      onClick?(...args): Promise<void>;
      handleDots?: boolean;
      handleDisabling: true;
    }
  | {
      onClick?(...args): void;
      handleDots?: false;
      handleDisabling?: false;
    }
);

export type IButtonProps<
  E extends React.ElementType
> = PolymorphicComponentProps<E, IButtonOwnProps>;

const defaultElement = 'button';

export const Button = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    props: IButtonProps<E>,
    innerRef: typeof props.ref
  ) => {
    const {
      className,
      size = 'm',
      theme = 'white',
      disabled = false,
      onClick = () => Promise.resolve(),
      dots = false,
      handleDots = false,
      handleDisabling = false,
      ref,
      children,
      ...restProps
    } = props;

    const actualAs = restProps.as || defaultElement;

    const [isProcessing, setProcessing] = useState(false);

    const handleClick = (...args) => {
      setProcessing(true);
      Promise.all([onClick(...args)])
        .catch(() => {
          /* do nothing */
        })
        .finally(() => {
          setProcessing(false);
        });
    };

    return (
      <Box
        ref={innerRef}
        as={defaultElement}
        className={cn(
          styles.button,
          styles[`button_size_${size}`],
          styles[`button_theme_${theme}`],
          { [styles.disabled]: disabled },
          className
        )}
        onClick={handleClick}
        {...(actualAs === 'button'
          ? { type: restProps.type || 'button' }
          : undefined)}
        {...restProps}
        disabled={(handleDisabling && isProcessing) || disabled}
      >
        <Dots
          className={styles.dots}
          active={dots || (handleDots && isProcessing)}
        >
          {children}
        </Dots>
      </Box>
    );
  }
) as <E extends React.ElementType = typeof defaultElement>(
  props: IButtonProps<E>
) => JSX.Element;

export default Button;
