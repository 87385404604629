import * as TYPES from './constants';

interface IJobAlertState {
  fetching: boolean;
}

const initialState: IJobAlertState = {
  fetching: false,
};

interface IJobAlertReducer {
  (state: IJobAlertState, action): IJobAlertState;
}

const jobAlertReducer: IJobAlertReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.FETCH_JOB_ALERT_NAME:
      return { ...state, fetching: true };

    case TYPES.FETCH_JOB_ALERT_NAME_SUCCESS:
      return { ...state, fetching: false, ...payload.job_alert };

    case TYPES.FETCH_JOB_ALERT_NAME_ERROR:
      return { ...state, fetching: false };

    default:
      return state;
  }
};

export default jobAlertReducer;
