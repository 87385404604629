import { SET_STATUS, STATUS } from './constants';

interface IScriptsState {
  [key: string]: STATUS;
}

const initialStare: IScriptsState = {};
interface IScriptsReducer {
  (state: IScriptsState, action): IScriptsState;
}

const scriptsReducer: IScriptsReducer = (
  state = initialStare,
  { type, payload }
) => {
  switch (type) {
    case SET_STATUS:
      return { ...state, [payload.src]: payload.status };

    default:
      return state;
  }
};

export default scriptsReducer;
