const onBoardingPopupStrategyRules = [
  {
    onClosePopupName: 'on-boarding-resume',
    action: ({ openPopup }) => openPopup('offers'),
  },
  {
    onClosePopupName: 'offers',
    action: ({ openPopup }) => openPopup('additional-questions'),
  },
  {
    onClosePopupName: 'additional-questions',
    action: ({ openPopup }) => openPopup('express-apply-jobs'),
  },
  {
    onClosePopupName: 'express-apply-jobs',
    action: ({ stopOnBoarding }) => stopOnBoarding(),
  },
];

const onBoardingHandlePopupNames = onBoardingPopupStrategyRules.map(
  (obj) => obj.onClosePopupName
);

export default onBoardingPopupStrategyRules;

export { onBoardingHandlePopupNames };
