export const POPUP_KEY = 'quick-offers';
export const ACTION_KEY = 'quick-offers';

export const FETCH_REQUEST = `${ACTION_KEY}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${ACTION_KEY}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${ACTION_KEY}/FETCH_FAILURE`;

export const ACCEPT_REQUEST = `${ACTION_KEY}/ACCEPT_REQUEST`;
export const ACCEPT_SUCCESS = `${ACTION_KEY}/ACCEPT_SUCCESS`;
export const ACCEPT_FAILURE = `${ACTION_KEY}/ACCEPT_FAILURE`;

export const REJECT_REQUEST = `${ACTION_KEY}/REJECT_REQUEST`;
export const REJECT_SUCCESS = `${ACTION_KEY}/REJECT_SUCCESS`;
export const REJECT_FAILURE = `${ACTION_KEY}/REJECT_FAILURE`;

export const RESET = `${ACTION_KEY}/RESET`;
