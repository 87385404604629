import type { SW } from '@types';
import type { IHttpClient } from '../../../../context/withHttpClient';

import { doNothing } from '../../../../constants';

import * as TYPES from './constants';

// Fetch Alert
const fetchAlert = () => ({
  type: TYPES.FETCH_ALERT,
});
const fetchAlertSuccess = (params) => ({
  type: TYPES.FETCH_ALERT_SUCCESS,
  payload: params,
});
const fetchAlertError = (params) => ({
  type: TYPES.FETCH_ALERT_ERROR,
  payload: params,
});

interface IFetchAppsAction {
  (httpClient: IHttpClient): SW.Redux.ThunkAction;
}

export const fetchAlertAction: IFetchAppsAction = (httpClient) => (
  dispatch
) => {
  dispatch(fetchAlert());

  httpClient
    .get('widgets/alert')
    .then((response) => {
      if (response.success) {
        dispatch(fetchAlertSuccess(response));
      } else {
        dispatch(fetchAlertError(response));
      }
    })
    .catch(doNothing);
};
