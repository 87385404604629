import type { SW } from '@types';
import type { IHttpClient } from 'context/withHttpClient';

import pick from 'lodash/pick';
import forEach from 'lodash/forEach';
import specializationList from '../../../../../caches/specializations_for_select_by_function_ids.json';
import * as TYPES from './constants';
import { fetchProfileDetailsAction } from '../../../pages/Profile/PersonalDetails/ProfileDetails/useProfileDetails/actions';
import { fetchAlertAction } from '../Alert/actions';
import { fetchWidgetOffersAction } from '../Offers/actions';
import { saveState } from '../../../../utils/local_storage';

const customIsEquals = (first, second) => {
  let ret = true;
  forEach(second, (value, key) => {
    if (key === 'resume') {
      if ((first[key] && first[key].id) !== (value && value.id)) {
        ret = false;
      }
    } else if (first[key] !== value) {
      ret = false;
    }
  });
  return ret;
};

const checkArray = [
  'resume',
  'education_id',
  'function_id',
  'last_salary_id',
  'specialization_id',
];

// Change Completeness
const changeCompleteness = (completeness) => ({
  type: TYPES.CHANGE_COMPLETENESS,
  payload: completeness,
});

interface IChangeCompletenessAction {
  (completeness, later?): SW.Redux.ThunkAction;
}

export const changeCompletenessAction: IChangeCompletenessAction = (
  completeness,
  later?
) => (dispatch) => {
  if (!later) {
    saveState(TYPES.LOCAL_STORAGE_COMPLETENESS, completeness);
  }
  dispatch(changeCompleteness(completeness));
};

// Fetch Completeness
const fetchCompleteness = () => ({
  type: TYPES.FETCH_COMPLETENESS,
});
const fetchCompletenessSuccess = (params) => ({
  type: TYPES.FETCH_COMPLETENESS_SUCCESS,
  payload: params,
});
const fetchCompletenessError = (params) => ({
  type: TYPES.FETCH_COMPLETENESS_ERROR,
  payload: params,
});

interface IFetchCompletenessAction {
  (httpClient: IHttpClient): SW.Redux.ThunkAction<Promise<void>>;
}

export const fetchCompletenessAction: IFetchCompletenessAction = (
  httpClient
) => (dispatch, getStore) => {
  dispatch(fetchCompleteness());
  dispatch(fetchAlertAction(httpClient));

  return httpClient.get('widgets/profile').then((response) => {
    if (response.success) {
      const currentCompleteness = customIsEquals(
        pick(response.profile, checkArray),
        pick(getStore().widgets.profile, checkArray)
      );

      if (!currentCompleteness) {
        dispatch(fetchWidgetOffersAction(httpClient));
      }

      response.count = [
        !response.profile.function_id,
        !response.profile.resume,
        !response.profile.last_salary_id,
        response.profile.function_id &&
          !!specializationList[response.profile.function_id].length &&
          !response.profile.specialization_id,
        !response.profile.education_id,
      ];

      if (!response.count.filter((v) => v).length) {
        dispatch(changeCompletenessAction(true));
      }

      dispatch(fetchCompletenessSuccess(response));
    } else {
      dispatch(fetchCompletenessError(response));
    }

    return response;
  });
};

// Patch Completeness
const patchCompleteness = (params) => ({
  type: TYPES.PATCH_COMPLETENESS,
  payload: params,
});
const patchCompletenessSuccess = (params) => ({
  type: TYPES.PATCH_COMPLETENESS_SUCCESS,
  payload: params,
});
const patchCompletenessError = (params) => ({
  type: TYPES.PATCH_COMPLETENESS_ERROR,
  payload: params,
});

interface IPatchCompletenessAction {
  (params, index, httpClient: IHttpClient): SW.Redux.ThunkAction<Promise<void>>;
}

export const patchCompletenessAction: IPatchCompletenessAction = (
  params,
  index,
  httpClient
) => (dispatch, getStore) => {
  dispatch(patchCompleteness(index));

  return httpClient.patch('widgets/profile', params).then((response) => {
    if (response.success) {
      const currentCompleteness = customIsEquals(
        pick(response.profile, checkArray),
        pick(getStore().widgets.profile, checkArray)
      );

      dispatch(patchCompletenessSuccess(response));
      dispatch(fetchAlertAction(httpClient));
      dispatch(fetchProfileDetailsAction(httpClient));

      response.count = [
        !response.profile.function_id,
        !response.profile.resume,
        !response.profile.last_salary_id,
        !!specializationList[response.profile.function_id].length &&
          !response.profile.specialization_id,
        !response.profile.education_id,
      ];

      if (!response.count.filter((v) => v).length) {
        dispatch(changeCompletenessAction(true));
      }

      if (!currentCompleteness) {
        dispatch(fetchWidgetOffersAction(httpClient));
      }
    } else {
      dispatch(patchCompletenessError(response));
    }
    return response;
  });
};
