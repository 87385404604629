import React from 'react';
import cn from 'classnames';
import { SW } from '@types';

import styles from './Button.pcss';

interface ISkeletonProps {
  className?: string;
  size?: SW.Styles.ISize;
  theme?: SW.Styles.ITheme;
}

export const Skeleton: React.FC<ISkeletonProps> = (props) => {
  const { className, size, theme, children } = props;
  return (
    <div
      className={cn(
        styles.button,
        styles[`button_size_${size}`],
        styles.button_theme_skeleton,
        styles[`button_theme_${theme}_skeleton`],
        className
      )}
    >
      {children}
    </div>
  );
};

Skeleton.defaultProps = {
  className: undefined,
  size: 'm',
  theme: 'white',
};

export default Skeleton;
